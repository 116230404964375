.footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: rgba(0, 10, 31, 1);
    width: 100%;
    min-height: 514px;
    padding-bottom: 24px;
    overflow: hidden;
    .logoMobileFooter {
        display: none;
    }
    .top {
        width: 1185px;
        height: 92px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);

        .phoen-addres {
            display: flex;
            flex-direction: column;
            gap: 6px;
            font-size: 12px;
            font-weight: 600;
            color: rgba(255, 255, 255, 1);

            div {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                gap: 15px;
                font-size: 18px;
                font-weight: 600;
                color: rgba(255, 255, 255, 1);
            }
        }

        @media (min-width: 1200px) and (max-width: 1300px) {
            width: 90%;
        }
    }
    .logoMobileFooter {
        display: none;
    }
    .bottom {
        width: 1180px;
        display: flex;
        flex-direction: column;
        margin-top: 42px;
        gap: 80px;

        @media (min-width: 1200px) and (max-width: 1300px) {
            width: 90%;
        }

        .links {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding-right: 70px;
        }

        ul {
            display: flex;
            flex-direction: column;
            gap: 15px;
            list-style-type: none;

            li {
                color: white;
                font-size: 14px;
                cursor: pointer;

                &:nth-child(1) {
                    font-family: "SpaceGrotesk";
                    font-size: 20px;
                    font-weight: 500;
                    cursor: default;
                }
            }
        }

        .legalActsD {
            .legalActsDH {
                padding-top: 10px;
                display: none;
                flex-direction: column;
                padding-left: 6px;
                gap: 10px;

                a {
                    color: white;
                    text-decoration: none;
                }
            }

            &:hover {
                .legalActsDH {
                    display: flex;
                }
            }
        }

        .usefulReferences {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            span {
                width: 100%;
                font-size: 20px;
                font-weight: 500;
                color: rgba(217, 219, 225, 1);
            }

            div {
                margin-top: 20px;
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-between;
                gap: 20px;

                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &:nth-child(3) {
                        img {
                            width: 250px;
                        }
                    }
                }

                img {
                    height: 60px;
                    max-height: 60px;
                }

                .downloadFromStore {
                    width: auto;
                    display: flex;
                    flex-direction: column;
                    gap: 12px;
                    margin-top: 0;

                    img {
                        height: 50px;
                    }
                }

                @media (max-width: 395px) {
                    .minRef {
                        display: flex;
                        justify-content: start;
                        overflow: hidden;
                        width: 100% !important;

                        img {
                            width: auto !important;
                            height: auto !important;
                        }
                        &:nth-child(2) {
                            order: -1;
                        }
                    }
                }
            }
            @media (max-width: 880px) {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                div {
                    margin-top: 20px;
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: space-evenly;
                    gap: 20px;

                    .longRef {
                        img {
                            width: 250px !important;
                        }
                    }
                    a {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                 
                    img {
                        height: 60px;
                    }

                    .downloadFromStore {
                        width: 100%;
                        flex-direction: row;
                        justify-content: space-evenly;
                        margin-top: 18px;
                        gap: 5px;
                    }
                }
            }
            @media (max-width: 450px) {
                div {
                    margin-top: 0px;
                    flex-wrap: wrap;
                    justify-content: start;

                    .minRef {
                        display: flex;
                        overflow: hidden;
                        // img {
                        //     width: 100% !important;
                        //     height: auto !important;
                        // }
                    }

                    .longRef {
                        width: 100% !important;

                        img {
                            width: 100% !important;
                        }
                    }

                    .downloadFromStore {
                        width: 100%;
                        flex-direction: row !important;
                        flex-wrap: nowrap;
                        justify-content: space-between;
                        margin-top: 10px;
                        gap: 10px;

                        a {
                            width: 48% !important;
                            img {
                                width: 100%;
                            }
                        }
                        @media (max-width: 340px) {
                            flex-direction: column !important;
                            margin-top: 0px;
                            gap: 20px;

                            a {
                                width: 100% !important;
                            }
                        }
                    }
                }
            }
        }

        .footerWarnings {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 12px;

            p {
                font-size: 13px;
                color: white;
                text-align: center;

                @media (max-width: 400px) {
                    font-size: 12px;
                }
            }
        }

        .contacts {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 8px;
            .icons {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 26px;

                a {
                    width: 22px;
                    height: 22px;
                    background-position: center;
                    background-repeat: no-repeat;

                    &:nth-child(1) {
                        background-image: url("../../assets/FaceBook.svg");

                        &:hover {
                            background-image: url("../../assets/FaceBook2.svg");
                        }
                    }
                    &:nth-child(2) {
                        background-image: url("../../assets/Instagram.svg");
                        &:hover {
                            background-image: url("../../assets/Instagram2.svg");
                        }
                    }
                    &:nth-child(3) {
                        background-image: url("../../assets/LinkedIn.svg");
                        &:hover {
                            background-image: url("../../assets/LinkedIn2.svg");
                        }
                    }
                    &:nth-child(4) {
                        background-image: url("../../assets/Telegram.svg");
                        &:hover {
                            background-image: url("../../assets/Telegram2.svg");
                        }
                    }
                }

                @media (max-width: 400px) {
                    gap: 18px;
                }
            }
            .copyright-links {
                display: flex;
                flex-wrap: wrap;
                align-items: baseline;
                justify-content: end;
                gap: 28px;
                row-gap: 0px;

                a {
                    text-decoration: none;
                }
                span {
                    text-align: right;
                    font-size: 12px;
                    font-weight: 500;
                    color: rgba(217, 219, 225, 1);
                }
            }
            @media (max-width: 450px) {
                flex-direction: column;
                gap: 30px;

                .icons {
                    align-items: center;
                    justify-content: space-evenly;
                    width: 80%;
                }
                .copyright-links {
                    align-items: center;
                    flex-direction: column;
                    gap: 5px;

                    span {
                        text-align: center;
                        font-size: 11px;
                    }
                    a {
                        text-align: center;
                    }
                }
            }
        }
    }
    .accordion {
        margin-top: 30px;
        width: 92.5vw;
        display: none;
        justify-content: center;

        .accordionItem {
            width: 92vw;
            min-height: 59px;
            padding: 0px 0px 5px 0px;
            border-bottom: 0.5px solid rgba(249, 249, 249, 0.1);

            .header {
                width: 92vw;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding-top: 16px;
                padding-bottom: 10px;

                p {
                    padding: 0 !important;
                    margin: 0 !important;
                    font-family: "SpaceGrotesk";
                    text-align: left;
                    font-weight: 500;
                    color: rgba(255, 255, 255, 1);
                    font-size: 20px;
                }

                .close {
                    position: relative;
                    margin-top: 5px;
                    height: 23px;
                }
                .open {
                    rotate: 180deg;
                }
            }
            .menuUl {
                list-style-type: none;

                li {
                    padding-block: 4px;
                    position: relative;
                    cursor: pointer;
                    font-size: 14px;

                    .navLink {
                        transition: 0s;
                        text-decoration: none;
                        color: rgba(255, 255, 255, 1);
                    }
                }
            }
            .legalActsD {
                .legalActsDH {
                    padding-top: 12px;
                    display: none;
                    flex-direction: column;
                    padding-left: 6px;
                    gap: 10px;
                }

                &:hover {
                    .legalActsDH {
                        display: flex;
                    }
                }
            }
        }
    }

    @media (max-width: 1200px) {
        height: auto;

        .top {
            width: 100%;
            height: auto;
            padding-block: 18px;
            padding-left: 32px;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            gap: 24px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);

            .logo {
                width: 160px;
                height: 19px;
            }

            .phoen-addres {
                div {
                    justify-content: flex-start;
                }
            }
        }
        .bottom {
            width: 100%;
            padding: 0px 32px 32px 32px;
            margin-top: 40px;
            gap: 70px;

            .links {
                display: none;
            }

            .constacts {
                gap: 22px;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
            }
        }
        .accordion {
            display: flex;
        }
    }
    @media (max-width: 750px) {
        .top {
            gap: 24px;
            padding-top: 0;
            align-items: flex-start;
            justify-content: flex-start;
        }
        .logo {
            display: none;
        }
        .logoMobileFooter {
            margin-top: 0;
            padding-top: 0;
            display: block !important;
        }
        .bottom {
            padding: 0px 24px 24px 24px;
            margin-top: 0px;
        }
        .accordion {
            margin-top: 30px;
            width: 90vw;

            .accordionItem {
                width: 85vw;

                .header {
                    width: 84vw;
                }
            }
        }
    }
    @media (max-width: 450px) {
        padding-bottom: 5px;

        .bottom {
            gap: 50px;
        }
    }
}
.navLinkF {
    text-decoration: none;
    color: white;
    font-size: 14px;
    cursor: pointer;
}
