.internalActs {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;
    background: #f2f3f5;
    padding: 62px 32px 150px 32px;
    min-height: 70vh;

    h1 {
        text-align: center;
        font-family: "SpaceGrotesk";
        color: rgb(43, 47, 56);
        font-size: 40px;
    }

    .iActs {
        margin-top: 56px;
        width: 100%;
        max-width: 1100px;
        display: flex;
        justify-content: space-between;
        gap: 20px;
        row-gap: 20px;
        flex-direction: row;
        flex-wrap: wrap;

        .accordionItems {
            flex-direction: row-reverse;
            justify-content: space-between;
            width: 48%;
            border-bottom: 1px solid #99a0b0;
            height: 54px;
            user-select: none;

            &:hover {
                background-color: inherit;
            }
        }
    }

    @media (max-width: 1150px) {
        padding: 56px 32px 86px 32px;

        .iActs {
            margin-top: 56px;
            display: flex;
            justify-content: space-between;
            gap: 15 px;
            flex-direction: row;
            flex-wrap: wrap;

            .accordionItems {
                width: 100%;
            }
        }
    }
    @media (max-width: 600px) {
        padding: 40px 24px 74px 24px;

        h1 {
            font-size: 28px !important;
        }
    }
}
