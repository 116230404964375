.termsCard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
    gap: 32px;
    width: 458px;

    img {
        width: 80px;
        height: 80px;
        margin-bottom: 0;
    }
    p {
        font-family: "SpaceGrotesk";
        font-size: 28px;
        font-weight: 500;
        color: rgba(43, 47, 56, 1);
    }

    ul {
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding-left: 28px;
        width: 440px;

        li {
            color: rgba(250, 77, 187, 1);
            font-size: 22px;
            line-height: 24px;

            span {
                text-align: left;
                color: rgba(78, 85, 102, 1);
                font-size: 16px;
                font-weight: 400;
            }
        }
    }

    @media (max-width: 1150px) {
        width: 90% !important;

        ul {
            width: 100%;

            li {
                width: 100%;

                span {
                    width: 100%;
                }
            }
        }
    }
    @media (max-width: 750px) {
        width: 90% !important;
    }
}
