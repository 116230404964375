.armenianMarkets {
    width: 100%;
    position: relative;
    padding-top: 64px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(249, 249, 249, 1);
    padding-bottom: 120px;
    overflow: hidden;

    span {
        font-size: 14px;
        font-weight: 600;
        color: rgba(78, 85, 102, 1);
    }
    h1 {
        margin-block: 16px;
        font-family: "SpaceGrotesk";
        font-size: 56px;
        font-weight: 500;
        color: rgba(43, 47, 56, 1);
        text-align: center;
    }
    h2 {
        max-width: 750px;
        font-size: 18px;
        font-weight: 400;
        color: rgba(78, 85, 102, 1);
        text-align: center;
        margin-bottom: 120px;
    }
    .graphics {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: white;
        max-width: 1140px;
        width: 92%;
        height: 754px;
        padding: 40px;
        border-radius: 32px;

        .highcharts-container {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            margin-top: 50px;
            width: auto !important;
            height: auto !important;
        }
        .highcharts-root {
            width: 1000px;
            @media (min-width: 1050px) and (max-width: 1180px) {
                width: 900px;
            }
            @media (min-width: 900px) and (max-width: 1050px) {
                width: 800px;
            }
            @media (min-width: 800px) and (max-width: 900px) {
                width: 700px;
            }
            @media (min-width: 700px) and (max-width: 800px) {
                width: 600px;
            }
            @media (min-width: 600px) and (max-width: 700px) {
                width: 500px;
            }
            @media (min-width: 500px) and (max-width: 600px) {
                width: 400px;
            }
            @media (min-width: 440px) and (max-width: 500px) {
                width: 350px;
            }
            @media (min-width: 370px) and (max-width: 440px) {
                width: 300px;
            }
            @media  (max-width: 370px) {
                width: 250px;
            }

            .highcharts-axis-title {
                display: none;
            }
            .highcharts-credits {
                display: none;
            }
        }
        h3 {
            font-size: 18px;
            font-weight: 600;
            color: rgba(43, 47, 56, 1);
            text-align: center;
        }
        p {
            text-align: center;
            margin-top: 4px;
            font-size: 14px;
            font-weight: 400;
            color: rgba(78, 85, 102, 1);
        }
        .wrapper {
            width: 100%;
            justify-content: flex-end;
            margin: 40px 16px 16px 16px;
            display: flex;
            border-radius: 5px;

            .option span {
                font-size: 14px;
                font-weight: 600;
                color: rgba(78, 85, 102, 1);
            }
            .option {
                background: #fff;
                width: 83px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 6px;
                cursor: pointer;
                border: 1px solid rgba(191, 196, 206, 1);
                transition: 0.2s;
                &:hover {
                    background: rgba(230, 231, 235, 1);
                }
                &:active {
                    background: rgba(191, 196, 206, 1);
                }
            }
            .option-1 {
                border-radius: 32px 0 0 32px;
                border-right: 0px;
            }
            .option-2 {
                border-radius: 0 32px 32px 0;
                border-left: 0px;
            }
            .dot {
                background-image: url("../../assets/Checked.svg");
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                display: none;
                padding-top: 1px;
            }
            input[type="radio"] {
                display: none;
            }
            #option-1:checked:checked ~ .option-1,
            #option-2:checked:checked ~ .option-2 {
                background: rgba(77, 89, 117, 1);
                &:hover {
                    background: rgba(153, 160, 176, 1);
                }
                &:active {
                    background: rgba(54, 62, 82, 1);
                }
            }
            #option-1:checked:checked ~ .option-1 .dot,
            #option-2:checked:checked ~ .option-2 .dot {
                display: block;
            }
            #option-1:checked:checked ~ .option-1 span,
            #option-2:checked:checked ~ .option-2 span {
                color: #fff;
            }
        }
        .chartSpan {
            position: absolute;
            z-index: 10;
            width: 96%;
            margin-left: 10px;
            font-size: 14px;
            font-weight: 600;
            color: rgba(78, 85, 102, 1);
        }
        .chartSpan1 {
            text-align: left;
            top: 190px;
            left: 4.7%;
        }
        .chartSpan2 {
            text-align: right;
            bottom: 88px;
            right: 5.7%;
        }
        @media (min-width: 530px) and (max-width: 600px) {
            .chartSpan1 {
                left: 10%;
            }
            .chartSpan2 {
                right: 12%;
                bottom: 85px;
            }
        }
        @media (min-width: 440px) and (max-width: 530px) {
            .chartSpan1 {
                left: 20px;
            }
            .chartSpan2 {
                right: 30px;
                bottom: 85px;
            }
        }
        @media  (max-width: 440px) {
            .chartSpan2 {
                bottom: 98px;
            }
        }

        @media  (max-width: 1050px) {
            padding: 30px;
        }
        @media  (max-width: 750px) {
            .wrapper {
                justify-content: center;
                .option {
                    width: 50%;
                }
            }
        }
    }

    @media  (max-width: 750px) {
        h1 {
            font-size: 40px;
            text-align: center;
            width: 90%;
            line-height: 42px;
        }
        h2 {
            font-size: 16px;
            margin-bottom: 40px;
            width: 80%;
        }
    }
}

.tooltipText {
    font-size: 14px !important;
    font-weight: 600 !important;
    color: rgba(43, 47, 56, 1) !important;
}
.tooltipInfo {
    font-weight: 400 !important;
    color: rgba(43, 47, 56, 1);
}

@media (max-width: 750px) {
    .tooltipText {
        font-size: 12px !important;
        text-align: left;
        display: inline-block;
        width: 200px;
        white-space: normal;
    }
    .tooltipInfo {
        font-size: 12px !important;
    }
}

// .custom-tooltip {
//     position: relative;
//     width: 217px;
//     min-height: 100px;
//     height: auto;
//     display: flex;
//     flex-direction: column;
//     align-items: flex-start;
//     padding: 16px;
// background: ;
//     border-radius: 24px;

//     .label {
//         text-align: left;
//         font-size: 14px;
//         font-weight: 400;
//         color: rgba(43, 47, 56, 1);

//         span {
//             font-size: 14px;
//             font-weight: 600;
//             color: rgba(43, 47, 56, 1);
//         }
//     }
//     button {
//         margin-top: 16px;
//         width: 185px;
//         height: 45px;
//         border-radius: 12px;
//         font-size: 14px;
//         font-weight: 600;
//         color: rgba(249, 249, 249, 1);
//     }
//     .btn-gov {
//         background: rgba(248, 0, 158, 1);
//     }
//     .btn-corp {
//         background: rgba(56, 152, 224, 1);
//     }
// }
