.blogPage {
    width: 100%;
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;
    background: #f2f3f5;
    padding: 62px 32px;
    // font-family: "SpaceGrotesk";

    .blogPageHeader {
        width: 100%;
        max-width: 1072px;
        // background-color: rgb(235, 235, 235);
        background-color: rgba(0, 0, 0, 0.02);
        border-radius: 24px;
        min-height: 280px;
        padding: 32px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 60px;

        .blogPageHeaderLeft {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-direction: column;

            .blogCategoryWrapper {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 10px;
                margin-bottom: 4px;

                span {
                    color: rgb(85, 87, 92);
                    font-size: 14px;
                    margin: 0;
                }
            }

            p {
                font-family: Navigo, Helvetica, "Helvetica New", Arial, sans-serif;
                font-weight: 600;
                font-size: 32px;
                line-height: 40px;
                color: rgb(43, 45, 51);
                margin-bottom: 10%;
            }

            div {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-wrap: wrap;
                gap: 14px;

                p {
                    margin: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 12px;
                    line-height: 12px;
                    color: rgb(43, 45, 51);
                    gap: 8px;
                }
            }
        }

        .blogPageIcon {
            width: 334px;
            height: 217px;
            border-radius: 12px;
        }
    }

    .blogPageSubtitleSection {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 15%;
        padding-block: 40px 90px !important;
        max-width: 1072px;

        .blogPageSubtitle {
            padding-left: 4px;
            width: 69%;
            font-size: 18px;
            font-weight: 400;
        }

        div {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
            gap: 5px;
            width: 26%;

            span {
                font-family: "ALS Hauss", Helvetica, "Helvetica New", Arial, sans-serif;
                font-size: 14px;
                line-height: 22px;
            }

            p {
                font-family: "ALS Hauss", Helvetica, "Helvetica New", Arial, sans-serif;
                font-size: 16px;
                font-weight: 900;
                line-height: 26px;
            }
        }
    }

    .blogPageContent {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        margin-bottom: 12px;
        max-width: 1072px;

        h1,
        h2,
        h3,
        h4 {
            margin-bottom: 32px;
            font-size: 24px;
            line-height: 32px;
            font-weight: 600;
            color: #575757;
        }

        p {
            line-height: 22px;
            font-size: 16px;
            margin-bottom: 24px;

            strong {
                color: black;
                font-size: 16.5px;
            }
        }

        a {
            color: #f8009e;
            text-decoration: none;
        }

        video {
            margin: auto;
            width: 60%;
            margin-bottom: 32px;

            @media (max-width: 750px) {
                width: 100%;
            }
        }

        .blogPageSectionImg {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 32px;

            img {
                width: 60%;
                border-radius: 8px;

                @media (max-width: 750px) {
                    width: 100%;
                }
            }
        }

        .blogPageSectionImgs {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 46px;
            // gap: 20px;

            img {
                width: 49%;
                border-radius: 8px;
            }
        }

        .blogPageSectionInformation,
        blockquote {
            display: flex;
            align-items: flex-start;
            position: relative;
            font-size: 14px;
            line-height: 22px;
            border-radius: 8px;
            padding: 16px 24px 16px 16px;
            width: 100%;
            gap: 12px;
            margin-bottom: 32px;
            color: rgb(43, 45, 51);
            background-color: rgb(235, 235, 235);

            p {
                margin-bottom: 0;
            }

            &::before {
                content: "";
                background-image: url("../../assets/BlogInformation.svg");
                background-size: cover;
                width: 22px;
                min-width: 22px;
                height: 22px;
                min-height: 22px;
            }
        }

        ul,
        ol {
            padding: 0;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 15px;
            padding-left: 16px !important;
            list-style-position: outside;
            margin-bottom: 35px;

            li {
                text-align: left;
                font-size: 16px;
                font-weight: 400;
                // color: rgb(78, 85, 102);
                color: rgb(43, 45, 51);

                &::marker {
                    // color: #5d083e37;
                    color: #fc4fbc6d;
                    // color: #fc4fbcb3;
                }

                @media (max-width: 480px) {
                    word-break: break-all;
                }

                img {
                    border-radius: 6px;
                    width: 100%;
                }
            }
        }

        figure {
            width: 100%;
            height: auto;
            border-radius: 8px;

            div {
                width: 100% !important;
                height: auto !important;
            }

            img {
                border-radius: 6px;
                width: 100%;
            }

            margin-bottom: 20px;
        }
    }

    .disclaimer {
        width: 100%;
        max-width: 1072px;
        margin-top: 30px;

        a {
            width: auto;
            font-size: 16px;
            border-bottom: 1.5px solid #000000a1;
            transition: 0s;
            cursor: pointer;
            text-decoration: none;
            color: #2b2f38;
        }
    }

    .page-up {
        display: none;
        align-items: center;
        justify-content: center;
        position: fixed;
        right: 35px;
        bottom: 35px;
        border-radius: 100%;
        background-color: none;
        background-color: rgba(255, 255, 255, 0.8);
        backdrop-filter: blur(150px);
        opacity: 0;

        img {
            width: 44px;
            height: 44px;
        }

        &:hover {
            opacity: 1 !important;
            transition: 0.1s;
        }

        &:active {
            transition: 0.1s;
            background-color: rgba(255, 255, 255, 1);

            img {
                width: 42px;
                height: 42px;
            }
        }
    }

    @media (max-width: 950px) {
        .blogPageHeader {
            min-height: 424px;
            padding: 0px;
            flex-direction: column-reverse;
            align-items: flex-start;
            gap: 0px;
            position: relative;
            height: auto;

            .blogPageHeaderLeft {
                padding: 20px;

                span {
                    font-size: 12px;
                }

                p {
                    font-size: 24px;
                    line-height: 32px;
                    margin-block: 8px 5%;
                    max-width: 90%;
                }

                div {
                    p {
                        font-weight: 400;
                    }
                }
            }

            .blogPageIcon {
                width: 100%;
                height: 240px !important;
                object-fit: cover;
            }
        }

        .blogPageSubtitleSection {
            flex-direction: column-reverse;
            padding-block: 40px 60px !important;
            padding: 0 2px;

            .blogPageSubtitle {
                width: 100%;
                font-size: 16px;
                line-height: 26px;
                padding-left: 0px;
            }

            div {
                gap: 4px;
                padding-bottom: 20px;
                margin-bottom: 20px;
                width: 100%;
                border-bottom: 1.5px solid rgb(233, 234, 234);

                p {
                    font-family: "ALS Hauss", Helvetica, "Helvetica New", Arial, sans-serif;
                }
            }
        }

        .blogPageContent {
            h1 {
                font-size: 26px;
                margin-bottom: 32px;
            }

            p {
                font-size: 16px;
                margin-bottom: 32px;
            }

            .blogPageSectionImg {
                margin-bottom: 32px;
            }

            // .blogPageSectionImgs {
            //     width: 100%;
            //     display: flex;
            //     align-items: center;
            //     justify-content: space-between;
            //     margin-bottom: 32px;
            //     // gap: 20px;

            //     img {
            //         width: 49%;
            //         border-radius: 8px;
            //     }
            // }

            .blogPageSectionInformation {
                margin-bottom: 42px;
            }

            ul {
                padding-left: 16px !important;
                padding: 0;
                gap: 15px;
                list-style-position: outside;
                margin-bottom: 35px;
            }
        }
    }

    @media (max-width: 580px) {
        .page-up {
            right: none;
            left: 30px;
            bottom: 30px;
            width: 40px;
            height: 40px;

            img {
                width: 40px;
                height: 40px;
            }

            &:active {
                right: 30.5px;
                bottom: 30.5px;

                img {
                    width: 38px;
                    height: 38px;
                }
            }
        }
    }
}
