.newsPage {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background: #f2f3f5;
    padding: 62px 32px 100px 32px;
    min-height: 70vh;

    h1 {
        text-align: left;
        font-family: "SpaceGrotesk";
        color: rgb(43, 47, 56);
        font-size: 32px;
        width: 80%;
        font-weight: 400;
        padding-bottom: 10px;
    }
    span {
        font-size: 14px;
        color: rgba(78, 85, 102, 1);
        width: 80%;
        text-align: left;
    }

    .newsContent {
        width: 80%;
        display: flex;
        flex-direction: column;
        gap: 0px;

        .swiper {
            width: 70%;
            height: 520px;
            display: flex;
            overflow: hidden;
            border-radius: 12px;
            margin-block: 32px !important;
            margin: 0px;
            background-color: rgba(0, 0, 0, 0.02);
            transition: 1s;

            .newsImgWrapper {
                display: flex;
                flex-direction: row;
                min-width: 100%;
            }
            .swiper-slide {
                width: 100%;
                min-width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                object-fit: cover;
                flex-direction: column;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 12px;
                }
            }
            .swiper-pagination-bullet {
                width: 8px;
                background: rgba(248, 0, 158, 0.75);
                // background: #000F30;
            }

            @media (max-width: 1525px) {
                width: 80%;
            }
            @media (max-width: 1300px) {
                width: 100%;
                height: 480px;
            }
        }

        .descContent * {
            font-size: 16px;
            line-height: 24px;
            text-align: left;
            color: #4e5566;
        }
    }
    @media (max-width: 1150px) {
        h1,
        span {
            width: 90%;
        }
        .newsContent {
            width: 90%;
        }
    }
    @media (max-width: 744px) {
        padding: 32px 24px 64px 24px;

        .swiper {
            height: 400px !important;
        }

        h1,
        span {
            width: 100%;
        }
        .newsContent {
            width: 100%;
        }
    }
    @media (max-width: 550px) {
        .swiper {
            height: 250px !important;
        }

        h1 {
            font-size: 26px;
            padding-bottom: 5px;
        }
        span {
            font-size: 12px;
        }
        .newsContent {
            .descContent * {
                font-size: 14px;
                line-height: 20px;
            }
        }
    }
}
