@font-face {
	font-family: "NotoSans";
	src: url("../fonts/NotoSans-Regular.ttf");
	font-display: swap;

	// @font-face {
	// 	font-family: "NotoSansArm";
	// 	src: url("../fonts/NotoSansArmenian-VariableFont_wdth\,wght.ttf");
	//  - for eng bold src: url("../fonts/NotoSans-SemiBold.ttf");
	// 	font-display: swap;
	// }
	// html:lang(am) * {
	// 	font-family: "NotoSansArm";
	// }

	// $color: #fffff0;
}
@font-face {
	font-family: "SpaceGrotesk";
	src: url("../fonts/SpaceGrotesk-Regular.ttf");
	font-display: swap;
}

* {
	font-family: "NotoSans";
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	border: 0;
	transition: 0.3s;
	-webkit-tap-highlight-color: transparent;
	caret-color: #f8009e;

	&::selection {
		background: rgba(250, 0, 158, 0.2);
	}
}

body {
	overflow-x: hidden;
}
.app {
	overflow: hidden;
	position: relative;
}
#root.block-scroll {
	height: 100vh;
	overflow-y: hidden;
}
.spaceGrotesk {
	font-family: "SpaceGrotesk";
}
button {
	cursor: pointer;
	background: inherit;
}
button > * {
	cursor: pointer;
}
img {
	pointer-events: none;
}
.ball {
	position: absolute;
	mix-blend-mode: overlay;
}

html:lang(en) .companyH {
	width: 226px !important;
}
html:lang(ru) .companyH {
	width: auto !important;
}
html:lang(en) .nContactUsH {
	width: 180px !important;
}
html:lang(en) .startInvesting {
	min-width: 110px !important;
}
.startInvesting {
	min-width: 110px !important;
}
// html:lang(am) .startInvesting {
// 	min-width: 168px !important;
// }
@media (min-width: 1150px) and (max-width: 1400px) {
	html:lang(am) .startInvesting,
	html:lang(ru) .startInvesting {
		min-width: 100px !important;
		width: auto !important;
		text-align: center;
		ul {
			li {
				text-align: left;
			}
		}
	}
}
html:lang(am) .startInvesting,
html:lang(ru) .startInvesting {
	text-align: center;
	ul {
		li {
			text-align: left;
		}
	}
}
html:lang(am) .nContactUs,
html:lang(ru) .nContactUs {
	text-align: center;

	ul {
		li {
			text-align: left;
		}
	}
}
html:lang(am) .startInvestingH {
	width: 228px !important;
}
html:lang(am) .text {
	font-size: 15px !important;
}
@media (max-width: 1150px) {
	html:lang(ru) .languageH {
		font-size: 15px !important;
		left: -40px !important;
	}
}
@media (max-width: 760px) {
	html:lang(en) .languageH {
		font-size: 15px !important;
		left: -20px !important;
	}
}
html:lang(ru) .cardLogin {
	width: auto !important;
	padding: 15px !important;
}
html:lang(am) .cardLogin {
	width: auto !important;
	padding: 15px !important;
}

@media (min-width: 1150px) {
	html:lang(am) .aboutUs {
		.middle {
			.text {
				height: 342px !important;
			}
		}
	}
}
// @media (max-width: 400px) {
// 	html:lang(en).recharts-default-legend {
// 		margin: 40px 0 0 11.5% !important;
// 		background-color: red !important;
// 	}
// 	html:lang(ru).recharts-default-legend {
// 		margin: 40px 0 0 11.5% !important;
// 		background-color: green !important;
// 	}
// 	html:lang(am).recharts-default-legend {
// 		margin: 40px 0 0 11.5% !important;
// 		background-color: blue !important;
// 	}
// }

body::-webkit-scrollbar {
	width: 4px;
}
body::-webkit-scrollbar-track {
	background-color: #000f301e;
}
body::-webkit-scrollbar-thumb {
	visibility: visible;
	background-color: #f8009e;
	box-shadow: inset 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
}
body::-webkit-scrollbar-thumb:hover {
	background-color: #d30086;
}
