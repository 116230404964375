.clientsRights {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;
    background: #f2f3f5;
    padding: 62px 32px 150px 32px;

    h1 {
        margin-bottom: 25px;
        font-family: "SpaceGrotesk";
        font-size: 56px;
        font-weight: 500;
        color: rgb(43, 47, 56);
        text-align: center;
    }

    div {
        width: 80%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        gap: 18px;
        text-align: left;
        a {
            text-decoration: none;
            color: #f8009e;
            font-weight: 600;
            padding: 0 6px;
        }
        p {
            text-align: left;
            font-size: 18px;
            font-weight: 400;
            color: rgb(78, 85, 102);
        }
        ul {
            padding-block: 10px;
            display: flex;
            flex-direction: column;
            gap: 15px;
            padding-left: 16px;
            // list-style-position: inside;

            li {
                text-align: left;
                font-size: 18px;
                font-weight: 400;
                color: rgb(78, 85, 102);

                &::marker {
                    color: #5d083e37;
                    // color: #fc4fbcb3;
                }

                @media (max-width: 480px) {
                    word-break: break-all;
                }
            }
        }
    }

    @media (max-width: 1350px) {
        div {
            width: 90%;
        }
    }
    @media (max-width: 750px) {
        padding: 62px 32px;

        h1 {
            font-size: 40px;
            text-align: center;
            line-height: 42px;
        }
        div {
            width: 100%;

            a {
                padding: 0 5px;
            }
            a,
            p,
            li {
                font-size: 16px;
            }
        }
    }
    @media (max-width: 550px) {
        padding-top: 32px;

        h1 {
            font-size: 40px;
            text-align: center;
            line-height: 42px;
        }
        div {
            width: 100%;

            a {
                padding: 0 5px;
            }
            a,
            p,
            li {
                font-size: 16px;
            }
        }
    }
}
