.researchCard {
  height: 370px;
  padding: 30px;
  width: 365px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 2px;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  user-select: none;
  cursor: pointer;
  position: relative;
  background-position: center;

  .researchHeader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .researchHeaderCategories {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;

      p {
        padding: 5px 10px 4.5px 10px;
        border-radius: 10px;
        font-size: 8px;
        line-height: 8px;
        letter-spacing: normal;
        text-align: center;
        text-transform: uppercase;
        color: #2b2f38;
        background: #fffff0;
        border: 1px solid #fffff0;
        transition: 0.4s;
        cursor: pointer;

        &:hover {
          background: transparent;
          border: 1px solid #fff;
          color: #fff;
          transition: 0.4s;
        }
      }
    }
    img {
      height: 24px;
    }
    .lockBlack {
      display: none;
    }
    .lockWhite {
      display: block;
    }
  }

  .researchContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;

    p,
    span {
      color: #fff;
      text-shadow: 1px 1px #0c0c0c;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
    }
    .offcut {
      display: block;
      width: 40%;
      height: 1px;
      background: #fffff0;
    }

    .bottom-block {
      text-transform: uppercase;
      font-size: 9px;
      margin-top: 12px;
    }
  }

  .mobile-research-background {
    display: none;
  }

  &:hover {
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.15);
  }

  @media (max-width: 1250px) {
    width: 31.5%;
  }
  @media (max-width: 1150px) {
    width: 100%;
    background-position: center;
  }
  @media (max-width: 770px) {
    height: calc(100vw - 62px);
    border-radius: 4px;
  }
  @media (max-width: 580px) {
    height: calc(100vw + 150px);
    border-radius: 2px;
    background-size: 0;
    background-color: white;
    padding: 0;
    min-width: 300px !important;
    min-height: 480px !important;

    .mobile-research-background {
      display: flex;
      width: 100%;
      height: 55%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .researchHeader {
      position: absolute;
      bottom: 39%;
      padding: 0 15px;

      .researchHeaderCategories {
        p {
          color: #2b2f38;
          background: inherit;
          border: 1px solid #2b2f38;

          &:hover {
            background: #2b2f38;
            border: 1px solid #2b2f38;
          }
        }
      }
      .lockBlack {
        display: block;
      }
      .lockWhite {
        display: none;
      }
    }

    .researchContent {
      padding: 0 15px;
      position: absolute;
      height: 37%;
      bottom: 0;

      p,
      span {
        color: #0c0c0c;
        text-shadow: none;
        -webkit-line-clamp: 4;
      }
      .offcut {
        background: #2b2f38;
      }

      .bottom-block {
        position: absolute;
        bottom: 15px;
      }
    }

    &::before {
      content: "";
      position: absolute;
      z-index: 2;
      bottom: 44.9%;

      left: 20px;
      border: 20px solid transparent;
      border-bottom: 14px solid #fff;
      transition: 0.3s;
    }

    &:hover {
      &::before {
        border-bottom: 14px solid #d9d9d9;
        transition: 0.3s;
      }
    }
  }
  @media (max-width: 400px) {
    // background-size: auto 52%;
    background-size: 0;

    .mobile-research-background {
      height: 52%;
    }

    .researchHeader {
      bottom: 41.5%;
      .researchHeaderCategories {
        p {
          font-size: 7px;
          padding: 4px 8px 3.5px 8px;
        }
      }
      .lockBlack {
        width: 17px;
      }
    }

    .researchContent {
      height: 40%;
    }

    &::before {
      bottom: 47.9%;
      left: 20px;
      border: 18px solid transparent;
      border-bottom: 12px solid #fff;
    }

    &:hover {
      &::before {
        border-bottom: 12px solid #d9d9d9;
      }
    }
  }
}
