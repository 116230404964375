.blogCard {
  max-width: 1072px;
  width: 100%;
  min-height: 194px;
  height: auto;
  // max-height: 194px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  user-select: none;
  // cursor: pointer;
  position: relative;
  gap: 80px;

  .blogCardContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .blogCardHeader {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 10px;
    gap: 16px;
    p {
      color: rgb(85, 87, 92);
      font-size: 14px;
      margin: 0px;
      cursor: pointer;

      &:hover {
        color: #2b2b2b;
      }
    }
  }

  .blogCardMain {
    p {
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      color: rgb(43, 45, 51);
      margin: 8px 0px;
      text-decoration: none;
      font-family: Navigo, Helvetica, "Helvetica New", Arial, sans-serif;
      cursor: pointer;
    }
    span {
      color: rgb(85, 87, 92);
      font-size: 14px;
      line-height: 22px;
      margin: 0px;
      width: inherit;
      -webkit-line-clamp: 3;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;

      // @media (max-width: 1023px) {
      //   .hxfrci {
      //     font-size: 12px;
      //     text-align: left;
      //     -webkit-line-clamp: 2;
      //   }
      // }
    }
  }

  .blogCardFooter {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap-reverse;
    gap: 14px;
    margin-top: 16px;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      // appearance: none;
      border-radius: 8px;
      font-weight: 500;
      transition: 0.4s;
      font-size: 14px;
      line-height: 22px;
      padding: 0px 20px;
      height: 36px;
      min-width: 36px;
      color: rgb(43, 45, 51);
      background-color: transparent;
      border: 2px solid rgb(213, 213, 214);

      &:hover {
        border-color: rgb(43, 45, 51);
      }
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 14px;

      p {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: rgb(43, 45, 51);
        gap: 8px;
      }
    }
  }

  .cardImg {
    width: 263px;
    height: 172px;
    border-radius: 12px;
    object-fit: cover;
    transition: 0s;
  }

  @media (max-width: 1150px) {
    width: 90%;
    gap: 50px;

    .blogCardHeader {
      margin-bottom: 0px;

      p {
        font-size: 13.5px;
      }
    }

    .blogCardMain {
      p {
        font-size: 22px;
        line-height: 28px;
        // margin: 6px 0;
      }
      span {
        font-size: 14px;
        line-height: 22px;
        // -webkit-line-clamp: 3;
      }
    }

    .blogCardFooter {
      width: 100%;
      gap: 14px;
      margin-top: 16px;

      // button {
      //   border-radius: 8px;
      //   font-size: 14px;
      //   line-height: 22px;
      //   padding: 0px 20px;
      //   height: 36px;
      //   min-width: 36px;
      // }

      // p {
      //   font-size: 12px;
      //   gap: 8px;
      // }
    }

    .cardImg {
      width: 220px;
      height: 144px;
      border-radius: 12px;
      object-fit: cover;

      @media (max-width: 400px) {
        height: 200px !important;
      }
      @media (max-width: 360px) {
        height: 180px !important;
      }
    }
  }

  @media (max-width: 950px) {
    flex-direction: column-reverse;
    width: 90%;
    gap: 22px;
    // max-width: 600px;

    .blogCardHeader {
      margin-bottom: 0px;

      p {
        font-size: 12px;
      }
    }

    .blogCardMain {
      p {
        font-size: 20px;
        line-height: 24px;
      }
      span {
        font-size: 12px;
        line-height: 18px;
        -webkit-line-clamp: 2;
      }
    }

    .blogCardFooter {
      width: 100%;
      gap: 14px;
      margin-top: 16px;
      justify-content: space-between;

      // button {
      //   border-radius: 8px;
      //   font-size: 14px;
      //   line-height: 22px;
      //   padding: 0px 20px;
      //   height: 36px;
      //   min-width: 36px;
      // }

      p {
        font-size: 12px;
        gap: 8px;
      }
    }

    .cardImg {
      width: 100%;
      height: 250px;
      transition: 0s !important;
    }
  }
  @media (max-width: 550px) {
    flex-direction: column-reverse;
    width: 100%;
    gap: 20px;

    .blogCardHeader {
      margin-bottom: 0px;

      p {
        font-size: 12px;
      }
    }

    .blogCardMain {
      p {
        font-size: 18px;
        line-height: 24px;
      }
      span {
        font-size: 12px;
        line-height: 22px;
        -webkit-line-clamp: 2;
      }
    }

    .blogCardFooter {
      width: 100%;
      gap: 14px;
      margin-top: 16px;
      justify-content: space-between;

      // button {
      //   border-radius: 8px;
      //   font-size: 14px;
      //   line-height: 22px;
      //   padding: 0px 20px;
      //   height: 36px;
      //   min-width: 36px;
      // }

      p {
        font-size: 12px;
        gap: 8px;
      }
    }

    .cardImg {
      width: 100%;
      height: 222px;
      transition: 0.3s !important;
    }
  }
}
