$pink: #ff50bf;
$gray: rgba(213, 213, 214, 0.45);

.pagination {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    .paginationPageBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        user-select: none;
        appearance: none;
        cursor: pointer;
        outline: 0px;
        border-radius: 4px;
        font-weight: 500;
        border-color: transparent;
        color: rgb(43, 45, 51);
        font-size: 14px;
        line-height: 22px;
        height: 32px;
        min-width: 32px;

        &:hover {
            background: rgb(232, 232, 232);
            color: $pink;
        }
        &:active {
            background: rgba(43, 45, 51, 0.2);
            // color: #f8009e;
        }
    }

    .activePaginationPageBtn {
        border-color: transparent;
        font-size: 14px;
        line-height: 22px;
        height: 32px;
        min-width: 32px;
        // color: $pink;
        background: $gray;

        &:hover {
            background: $gray;
        }
        &:active {
            background: $gray;
        }
    }

    .paginationBackward,
    .paginationForward {
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        user-select: none;
        appearance: none;
        cursor: pointer;
        outline: 0px;
        // text-overflow: ellipsis;
        border-radius: 4px;
        font-weight: 500;
        border-color: transparent;
        color: rgb(43, 45, 51);
        font-size: 14px;
        line-height: 22px;
        height: 32px;
        min-width: 32px;

        &:hover {
            background: rgb(232, 232, 232);

            img {
                filter: invert(0.5) sepia(1) saturate(3) hue-rotate(265deg) drop-shadow(0px 0px 10px #ff00a2);
            }
        }
        &:active {
            background: rgba(43, 45, 51, 0.2);
        }
    }
}
