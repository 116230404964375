.research-category {
    width: 100%;
    min-height: 37px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    // border-bottom: 1px solid #e8e8e8;
    overflow: hidden;

    .checkbox-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border-radius: 6px;
        border: 1px solid black;

        img{
            margin: 1px 0px 0px -1px;
        }
    }
    label {
        font-size: 16px;
        white-space: wrap;
        // color: #121212;
    }
}

// .research-category-active {
//     // background: rgb(55, 55, 55, 0.75);
//     background: rgb(170, 170, 170, 0.9);
//     padding: 0 8px 0 4px;

//     img {
//         display: flex;
//         width: 15px;
//         border-radius: 2px;
//         filter: brightness(0.3);
//     }

//     p {
//         filter: brightness(0.5);
//     }

//     // &:hover {
//     //     img,
//     //     p {
//     //         filter: brightness(0.3);
//     //     }
//     // }
// }
