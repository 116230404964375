.aboutUs {
    width: 100%;
    position: relative;
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(249, 249, 249, 1);
    overflow: hidden;

    .ball1-2 {
        left: 0;
        top: -50px;
        @media (max-width: 2000px) {
            left: -150px;
        }
    }
    .ball2-2 {
        right: 0;
        top: 850px;
        @media (max-width: 2000px) {
            right: -150px;
        }
    }
    .logoAboutUs {
        z-index: 1;
    }
    .about {
        margin-top: 56px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 16px;
        z-index: 1;

        span {
            font-size: 14px;
            font-weight: 600;
            color: rgba(78, 85, 102, 1);
        }
        h1 {
            font-family: "SpaceGrotesk";
            font-size: 40px;
            font-weight: 500;
            line-height: 44px;
            letter-spacing: 0em;
            text-align: center;
        }
        h2 {
            width: 560px;
            font-size: 18px;
            font-weight: 400;
            color: rgba(78, 85, 102, 1);
        }
    }
    .middle {
        position: relative;
        display: flex;
        margin-block: 125px;

        .text {
            margin-left: -135px;
            width: 426px;
            height: 304px;
            border-radius: 32px;
            padding: 32px;
            background: white;
            z-index: 100 !important;
        }
        img {
            margin-top: 154px;
            z-index: 0;
        }
    }
    .team {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 96px;
        margin-bottom: 120px;

        .title {
            font-family: "SpaceGrotesk";
            font-size: 40px;
            font-weight: 500;
            color: rgba(43, 47, 56, 1);
        }
        .cards {
            width: 1140px;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            gap: 30px;
            row-gap: 94px;
        }
    }
    .photo2 {
        display: none;
    }
    .photo3 {
        display: none !important;
    }

    @media (min-width: 751px) and (max-width: 1250px) {
        .team {
            margin-bottom: 56px;

            .cards {
                width: 90%;
                display: flex;
                flex-wrap: wrap;
                gap: 4%;
                row-gap: 94px;

                .teamCard {
                    display: flex;
                    flex-direction: column;
                    width: 48%;
                }
            }
        }
    }

    @media (max-width: 1150px) {
        width: 100%;
        padding-top: 56px;

        .about {
            h2 {
                width: 90%;
            }
        }
        // .middle {
        //     position: relative;
        //     display: flex;
        //     margin-block: 112px;
        //     justify-content: center;

        //     .text {
        //         margin-left: 0px;
        //         width: 631px;
        //         height: auto;
        //         // line-height: 24px;
        //     }
        //     img {
        //         margin-top: 154px;
        //         z-index: 0;
        //     }
        // }
        .middle {
            margin-block: 112px;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            flex-wrap: wrap-reverse;
            gap: 200px;
            row-gap: 0px !important;

            .text {
                margin-left: 0px;
                width: 631px;
                height: 250px;
            }
            img {
                margin-top: -40px;
                z-index: 0;
            }
        }
    }
    @media (max-width: 750px) {
        padding-top: 40px;
        padding-left: 24px;
        padding-right: 24px;
        .about {
            span {
                font-size: 14px;
            }
            h1 {
                font-size: 28px;
            }
            h2 {
                font-size: 16px;
                width: 100%;
            }
        }
        .middle {
            margin-block: 80px;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            flex-wrap: wrap-reverse;
            gap: 200px;
            row-gap: 0px !important;

            .text {
                margin-left: 0px;
                width: 100%;
                height: auto;
                margin-left: 0px;
            }
            img {
                display: none;
            }
            .photo2 {
                width: 100%;
                margin-top: -40px;
                display: block;
                border-radius: 32px;
            }
        }
        .team {
            margin-bottom: 56px;

            .title {
                font-size: 28px;
                z-index: 2;
            }
            .cards {
                width: 90%;
                display: flex;
                flex-wrap: wrap;
                gap: 4%;
                row-gap: 94px;

                .teamCard {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                }
            }
        }
    }
    @media (max-width: 430px) {
        .team {
            margin-bottom: 64px;

            .cards {
                width: 90%;
                display: flex;
                flex-wrap: wrap;
                gap: 4%;
                row-gap: 94px;

                .teamCard {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                }
            }
        }
        .middle {
            margin-block: 80px;
         
            .photo2 {
                width: 100%;
                margin-top: -40px;
                display: none;
                border-radius: 32px;
            }
            .photo3 {
                display: block !important;
            }
        }
    }
}
