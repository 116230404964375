.documents {
    width: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    background: #f2f3f5;
    padding: 62px 32px 150px 32px;
    min-height: 70vh;   
    overflow: hidden;

    .ball4-1 {
        left: -50px;
        top: 0;
    }
    .ball4-2 {
        right: 0px;
        bottom: -200px;
        @media (max-width: 2000px) {
            right: -50px;
            bottom: -250px;
        }
    }

    h1 {
        font-family: "SpaceGrotesk";
        font-size: 40px;
        font-weight: 500;
        color: rgba(43, 47, 56, 1);
        z-index: 2;
        text-align: center;
    }
    .cardsDocument {
        width: 100%;
        margin-top: 56px;
        display: flex;
        justify-content: center;
        gap: 30px;
        margin-bottom: 128px;
        z-index: 2;

        @media (min-width: 731px) and (max-width: 915px) {
            flex-wrap: wrap;
            gap: 24px;
            row-gap: 24px;
            .documentCard {
                display: flex;
                width: 48.2%;
                max-width: 100%;
            }
            // .documentCard:nth-child(3) {
            //     width: 100% !important;
            // }
        }
        @media (min-width: 601px) and (max-width: 731px) {
            flex-wrap: wrap;
            gap: 24px;
            row-gap: 24px;
            .documentCard {
                display: flex;
                width: 47%;
                max-width: 100%;
            }
            // .documentCard:nth-child(3) {
            //     width: 100% !important;
            // }
        }

        @media (max-width: 600px) {
            flex-wrap: wrap;
            gap: 24px;
            row-gap: 24px;
            .documentCard {
                display: flex;
                width: 100%;
                max-width: 100%;
            }
        }
    }

    @media (max-width: 1150px) {
        .cardsDocument {
            gap: 24px;
        }
    }
    @media (max-width: 600px) {
        padding: 40px 24px 88px 24px;

        .ball4-1 {
            display: none;
        }
        .ball4-2 {
            display: none;
        }

        h1 {
            font-size: 28px;
        }
    }
}
