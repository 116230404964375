.step {
    position: relative;
    width: 360px;
    height: 193px;
    padding: 32px;
    border-radius: 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    background: white;
    box-shadow: 0 10px 40px 10px rgba(51, 51, 79, 0.06);

    span {
        position: absolute;
        top: -62px;
        font-size: 72px;
        font-weight: 600;
        color: rgba(250, 77, 187, 0.7);
    }
    .title {
        font-family: "SpaceGrotesk";
        font-size: 24px;
        font-weight: 500;
        color: rgba(43, 47, 56, 1);
    }
    p {
        font-size: 16px;
        font-weight: 400;
        color: rgba(78, 85, 102, 1);
    }

    @media (min-width: 916px) and (max-width: 1150px) {
        max-width: 304px;
        width: 33%;
        min-width: 265px;
        height: 181px;
    }

    @media (min-width: 601px) and (max-width: 915px) {
        display: flex;
        width: 48%;
        &:nth-child(3){
            width: 98%;
        }
    }
    @media (min-width: 230px) and (max-width: 600px) {
        display: flex;
        width: 98%;
        &:nth-child(3){
            width: 98%;
        }
    }
}
