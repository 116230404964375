.documents {
    width: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    background: #f2f3f5;
    padding: 62px 32px 150px 32px;
    padding-top: 62px;
    padding-bottom: 150px;
    overflow: hidden;

    .ball4-1 {
        left: -50px;
        top: 0;
    }
    .ball4-2 {
        right: 0px;
        bottom: -200px;
        @media (max-width: 2000px) {
            right: -50px;
            bottom: -250px;
        }
    }

    h1 {
        font-family: "SpaceGrotesk";
        font-size: 40px;
        font-weight: 500;
        color: rgba(43, 47, 56, 1);
        z-index: 2;
    }
    .cardsDocument {
        width: 100%;
        margin-top: 56px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 30px;
        margin-bottom: 128px;
        z-index: 2;

        @media (min-width: 731px) and (max-width: 915px) {
            flex-wrap: wrap;
            gap: 24px;
            row-gap: 24px;
            .documentCard {
                display: flex;
                width: 48.2%;
                max-width: 100%;
            }
            .documentCard:nth-last-child(1):nth-child(odd) {
                width: 100% !important;
            }
        }
        @media (min-width: 601px) and (max-width: 731px) {
            flex-wrap: wrap;
            gap: 24px;
            row-gap: 24px;
            .documentCard {
                display: flex;
                width: 47%;
                max-width: 100%;
            }
            .documentCard:nth-last-child(1):nth-child(odd) {
                width: 100% !important;
            }
        }

        @media (min-width: 230px) and (max-width: 600px) {
            flex-wrap: wrap;
            gap: 24px;
            row-gap: 24px;
            .documentCard {
                display: flex;
                width: 100%;
                max-width: 100%;
            }
        }
    }
    h2 {
        font-family: "SpaceGrotesk";
        font-size: 28px;
        font-weight: 500;
        color: rgba(43, 47, 56, 1);
        margin-bottom: 50px;
        z-index: 2;
    }
    .accordion {
        z-index: 2;

        .accordionItem {
            width: 555px;
            min-height: 59px;
            padding: 0px 0px 5px 0px;
            border-bottom: 0.5px solid rgba(153, 160, 176, 1);

            .header {
                width: 555px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding-top: 16px;
                padding-bottom: 8px;

                p {
                    padding: 0 !important;
                    margin: 0 !important;
                    font-family: "SpaceGrotesk";
                    font-size: 24px;
                    font-weight: 500;
                    color: rgba(43, 47, 56, 1);
                }
                .close {
                    position: relative;
                    margin-right: 23px;
                    margin-top: 13px;

                    &::before {
                        content: "";
                        position: absolute;
                        width: 18px;
                        height: 3px;
                        border-radius: 50px;
                        background: rgba(43, 47, 56, 1);
                    }
                    &::after {
                        content: "";
                        transition: 0.3s;
                        position: absolute;
                        rotate: 90deg;
                        width: 18px;
                        height: 3px;
                        border-radius: 50px;
                        background: rgba(43, 47, 56, 1);
                    }
                }
                .open::after {
                    rotate: 180deg;
                }
            }
        }
    }

    @media (min-width: 300px) and (max-width: 1150px) {
        .cardsDocument {
            gap: 24px;
        }
        .accordion {
            z-index: 2;
            width: 100%;
            .accordionItem {
                width: 100% !important;
                .szh-accordion__item-btn {
                    width: 100%;
                }
                .header {
                    width: 100%;
                }
            }
        }
    }
    @media (min-width: 230px) and (max-width: 600px) {
        padding: 40px 24px 88px 24px;

        .ball4-1 {
            display: none;
        }
        .ball4-2 {
            display: none;
        }

        h1 {
            font-size: 28px;
        }
        h2 {
            font-size: 24px;
            text-align: center;
        }
        .accordion {
            .accordionItem {
                .header {
                    p {
                        font-size: 20px;
                    }
                }
            }
        }
    }
}
