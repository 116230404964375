.safeKeeping {
    text-align: center;
    padding-bottom: 120px;
    position: relative;
    overflow: hidden;

    .ball6-1 {
        left: 0;
        top: -50px;
    }
    .ball6-2 {
        right: 0;
        top: 580px;
        @media (max-width: 2000px) {
            right: -180px;
        }
    }

    span {
        font-size: 14px;
        font-weight: 600;
        color: rgba(78, 85, 102, 1);
        z-index: 2;
    }
    h1 {
        max-width: 700px;
        z-index: 2;
    }
    h2 {
        max-width: 700px;
        margin-bottom: 56px;
        z-index: 2;
    }
    img {
        margin-bottom: 128px;
        z-index: 2;
    }
    .terms {
        display: flex;
        align-items: flex-start;
        width: 100%;
        justify-content: space-evenly;
        z-index: 2;
    }

    @media (max-width: 1150px) {
        padding-bottom: 80px;

        .terms {
            gap: 64px;
            align-items: center;
            flex-direction: column;
        }
    }
    @media (max-width: 530px) {
        .terms {
            gap: 40px;
            align-items: center;
            flex-direction: column;
        }
        .imgSC {
            width: 350px;
        }
    }
    @media (max-width: 400px) {
        .imgSC {
            width: 300px;
        }
    }
}
