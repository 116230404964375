.researchPage {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background: #f2f3f5;
    min-height: 70vh;

    .researchPageHeader {
        width: 100%;
        height: 400px;
        // background-image: url("../../assets/Research1.jpeg") !important;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-bottom: 25px;
        flex-direction: column;

        .headerContent {
            width: 60%;
            max-width: 800px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;

            .headerContentCategories {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 8px;

                p {
                    padding: 5px 10px 4.5px 10px;
                    border-radius: 10px;
                    font-size: 8px;
                    line-height: 8px;
                    letter-spacing: normal;
                    text-align: center;
                    text-transform: uppercase;
                    color: #fff;
                    border: 1px solid #fffff0;
                    transition: 0.4s;
                    // cursor: pointer;
                    text-shadow: 0.5px 0.5px #0c0c0c;

                    &:hover {
                        background: transparent;
                        border: 1px solid #fff;
                        color: #2b2f38;
                        background: #fffff0;
                        transition: 0.4s;
                        text-shadow: 0.5px 0.5px #00000000;
                    }
                }
            }

            h1 {
                color: #f9f9f9;
                max-width: 85%;
                text-shadow: 1px 1px #0c0c0c;
            }

            .headerBottomContent {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                span {
                    color: #f9f9f9;
                    font-size: 10px;
                    text-shadow: 1px 1px #0c0c0c;
                }
                a {
                    width: 16px;
                    height: 16px;
                    background-image: url("../../assets/Print.svg");
                    background-position: center;
                    background-size: 16px;
                    transition: 0.2s;

                    &:hover {
                        background-image: url("../../assets/PrintPink1.svg");
                    }
                }
            }
        }
    }
    .researchPageContent {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 60%;

        .authors {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            gap: 28px;
            padding-block: 30px 28px;
            flex-direction: column;
            width: 100%;
            max-width: 800px;
            border-bottom: 1px solid #e0e0e0;
        }

        .authors-field {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 32px;

            img {
                width: 60px;
                height: 60px;
                border-radius: 100%;
            }
            div {
                display: flex;
                flex-direction: column;
                gap: 4px;

                p {
                    font-size: 16px;
                    color: #0c0c0c;
                }
                span {
                    font-size: 12px;
                    color: #7f7f7f;
                }
            }
        }
        .researchPageMaterial {
            max-width: 800px;
            width: 100%;
            display: flex;
            flex-direction: column;
            text-align: left;

            #pdf-controls,
            #pdf-page-info {
                display: none;
            }
            #pdf-page-wrapper {
                margin: 0;
            }
            .react-pdf__Document {
                margin: 0;
            }
            .react-pdf__Page__canvas {
                width: 100% !important;
                height: auto !important;
            }
            .textLayer {
                display: none;
            }
            .react-pdf__Page {
                width: 100% !important;
                max-width: 100% !important;
                min-width: 100% !important;
                min-height: 0px !important;
                max-height: 100% !important;
            }
            .react-pdf__Page__canvas {
                width: 100% !important;
                max-width: 100% !important;
                min-width: 100% !important;
                height: auto !important;
            }
            .react-pdf__Page__annotations,
            .react-pdf__Page__textContent {
                height: 0px !important;
                max-height: 0px !important;
            }
            #pdf-toggle-pagination {
                display: none !important;
            }
            #pdf-controls {
                display: none !important;
            }
            #pdf-page-info {
                display: none !important;
            }
        }
        .pleaseLogIn {
            width: 100%;
            max-width: 800px;
            margin-block: 30px;
            display: flex;
            flex-direction: column;
            border: 1px solid #e0e0e0;
            border-radius: 5px;
            padding: 30px 90px 30px 30px;

            p {
                font-size: 24px;
                margin-bottom: 24px;
            }
            span {
                font-size: 15px;
                margin-bottom: 30px;
            }

            form {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 12px;
                position: relative;
            }
            input {
                width: 100%;
                background-color: inherit;
                padding: 6px 2.5px;
                color: #0c0c0c;
                border-bottom: 1px solid #e0e0e0;
                font-size: 15px;
                line-height: 18px;
                position: relative;

                // &:after {
                //     content: "";
                //     position: absolute;
                //     top: 0;
                //     left: 0;
                //     width: 20px;
                //     height: 20px;
                //     background-color: red;
                //     z-index: 100;
                // }

                &:active {
                    outline: none;
                    border-bottom: 1px solid #747474;
                }
                &:focus {
                    outline: none;
                    border-bottom: 1px solid #747474;
                }
            }

            // input[type="text"] {
            //     &::after {
            //         // content: "Введите ваш номер телефона";
            //         // position: absolute;
            //         // top: 0;
            //         // font-size: 12px;
            //         // width: 100%;
            //         // height: 12px;
            //         // background-color: red;
            //     }
            // }

            div {
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                margin-top: 30px;

                button {
                    background: #333;
                    color: #fff;
                    padding: 10px 20px;
                    border-radius: 5px;
                    text-decoration: none;
                }

                p {
                    padding: 0;
                    margin: 0;
                    font-size: 12px;

                    a {
                        padding-left: 6px;
                        color: #f8009e;
                        transition: 0.4s;

                        &:hover {
                            color: #c9008c;
                        }
                    }
                }
            }
        }

        .disclaimer {
            width: 100%;
            max-width: 800px;
            margin-block: 30px;

            a {
                width: auto;
                font-size: 16px;
                border-bottom: 1.5px solid #000000a1;
                transition: 0s;
                cursor: pointer;
                text-decoration: none;
                color: #2b2f38;
            }
        }
    }

    .page-up {
        display: none;
        align-items: center;
        justify-content: center;
        position: fixed;
        right: 35px;
        bottom: 35px;
        border-radius: 100%;
        background-color: none;
        background-color: rgba(255, 255, 255, 0.8);
        backdrop-filter: blur(150px);
        opacity: 0;

        img {
            width: 44px;
            height: 44px;
        }

        &:hover {
            opacity: 1 !important;
            transition: 0.1s;
        }

        &:active {
            transition: 0.1s;
            background-color: rgba(255, 255, 255, 1);

            img {
                width: 42px;
                height: 42px;
            }
        }
    }

    @media (max-width: 1150px) {
        .researchPageHeader {
            .headerContent {
                width: 80%;
                max-width: 900px;

                h1 {
                    font-size: 20px;
                }
            }
        }

        .researchPageContent {
            width: 80%;

            .authors {
                gap: 18px;
                padding-block: 22px 20px;
                width: 100%;
                max-width: 900px;
            }
            .authors-field {
                // gap: 26px;

                img {
                    width: 55px;
                    height: 55px;
                }
                div {
                    p {
                        font-size: 16px;
                    }
                    span {
                        font-size: 12px;
                    }
                }
            }
            .researchPageMaterial {
                max-width: 800px;
                width: 100%;
                display: flex;
                flex-direction: column;
                text-align: left;
            }
            .pleaseLogIn {
                width: 100%;
                max-width: 800px;
                margin-block: 30px;
                display: flex;
                flex-direction: column;
                border: 1px solid #e0e0e0;
                border-radius: 5px;
                padding: 30px 90px 30px 30px;

                p {
                    font-size: 24px;
                    margin-bottom: 24px;
                }
                span {
                    font-size: 15px;
                    margin-bottom: 30px;
                }

                form {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 12px;
                    position: relative;
                }
                input {
                    width: 100%;
                    background-color: inherit;
                    padding: 6px 2.5px;
                    color: #0c0c0c;
                    border-bottom: 1px solid #e0e0e0;
                    font-size: 15px;
                    line-height: 18px;
                    position: relative;

                    // &:after {
                    //     content: "";
                    //     position: absolute;
                    //     top: 0;
                    //     left: 0;
                    //     width: 20px;
                    //     height: 20px;
                    //     background-color: red;
                    //     z-index: 100;
                    // }

                    &:active {
                        outline: none;
                        border-bottom: 1px solid #747474;
                    }
                    &:focus {
                        outline: none;
                        border-bottom: 1px solid #747474;
                    }
                }

                // input[type="text"] {
                //     &::after {
                //         // content: "Введите ваш номер телефона";
                //         // position: absolute;
                //         // top: 0;
                //         // font-size: 12px;
                //         // width: 100%;
                //         // height: 12px;
                //         // background-color: red;
                //     }
                // }

                div {
                    display: flex;
                    align-items: flex-end;
                    justify-content: space-between;
                    margin-top: 30px;

                    button {
                        background: #333;
                        color: #fff;
                        padding: 10px 20px;
                        border-radius: 5px;
                        text-decoration: none;
                    }

                    p {
                        padding: 0;
                        margin: 0;
                        font-size: 12px;

                        a {
                            padding-left: 6px;
                            color: #f8009e;
                            transition: 0.4s;

                            &:hover {
                                color: #c9008c;
                            }
                        }
                    }
                }
            }

            .disclaimer {
                width: 100%;
                max-width: 800px;
                margin-block: 30px;

                span {
                    width: auto;
                    font-size: 16px;
                    border-bottom: 1.5px solid #000000a1;
                    transition: 0s;
                    cursor: pointer;
                }
            }
        }
    }
    @media (max-width: 580px) {
        .researchPageHeader {
            height: 380px;

            .headerContent {
                width: 100%;
                max-width: none;
                padding: 0 30px;

                p {
                    font-size: 7px;
                    padding: 4px 8px 3.5px 8px;
                }

                h1 {
                    font-size: 18px;
                }
            }
        }

        .researchPageContent {
            width: 80%;

            .authors {
                gap: 18px;
                padding-block: 22px 20px;
                width: 100%;
                max-width: 900px;
            }
            .authors-field {
                gap: 26px;

                img {
                    width: 55px;
                    height: 55px;
                }
                div {
                    gap: 2px;

                    p {
                        font-size: 16px;
                    }
                    span {
                        font-size: 12px;
                        line-height: 16px;
                    }
                }
            }
            .researchPageMaterial {
                max-width: 800px;
                width: 100%;
                display: flex;
                flex-direction: column;
                text-align: left;
            }
            .pleaseLogIn {
                margin-block: 20px;
                padding: 20px;

                p {
                    font-size: 20px;
                    margin-bottom: 4px;
                }
                span {
                    font-size: 13px;
                    margin-bottom: 20px;
                }
                form {
                    flex-direction: column;
                    gap: 14px;
                }

                div {
                    align-items: flex-start;
                    flex-direction: column;
                    gap: 18px;
                    margin-top: 30px;

                    button {
                        width: 100%;
                    }
                    p {
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                    }
                }
            }

            .disclaimer {
                margin-block: 30px;

                span {
                    font-size: 14px;
                }
            }
        }

        .page-up {
            right: none;
            left: 30px;
            bottom: 30px;
            width: 40px;
            height: 40px;

            img {
                width: 40px;
                height: 40px;
            }

            &:active {
                right: 30.5px;
                bottom: 30.5px;

                img {
                    width: 38px;
                    height: 38px;
                }
            }
        }
    }
}
