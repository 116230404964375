.newsCard {
  width: 23%;
  min-width: 23%;
  max-width: 420px;
  height: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 16px;
  box-shadow: 0 8px 8px -4px rgb(255, 138, 212);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  overflow: hidden;
  user-select: none;
  cursor: pointer;

  .newsContent {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    text-align: left;
    gap: 4px;
    position: relative;

    p {
      width: 100%;
      font-family: "SpaceGrotesk";
      font-size: 20px;
      font-weight: 620;
      line-height: 26px;
      color: #2b2f38;
      max-height: 52px;
      overflow: hidden;
    }
    span,
    span * {
      width: 100%;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #2b2f38;
      max-height: 60px;
      overflow: hidden;
      transition: 0.25s;
    }
    &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: -25px;
      right: -25px;
      box-shadow: inset white 0 -15px 10px;
    }
  }
  .newsDate {
    overflow: hidden;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid rgba(191, 196, 206, 0.3);
    padding: 8px 2px 0 2px;
    position: relative;
    min-height: 29px;
    transition: padding 0s;

    img {
      width: 16px;
      height: 18px;
    }

    span {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #2b2f38;
    }
  }

  &:hover {
    box-shadow: none;
    padding-bottom: 0px;

    .newsContent {
      span {
        transition: max-height 0.4s;
        max-height: 0px;
      }
      &:after {
        opacity: 0 !important;
      }
    }

    .newsDate {
      opacity: 0;
      min-height: 0px;
      height: 0px;
      padding: 0;
      border-top: 0px solid rgba(191, 196, 206, 0.7);
      // transition:
      //   all 0.2s,
      //   padding 0.3s;
      transition: 0.3s;
    }
  }

  @media (max-width: 1650px) {
    width: 31%;
    min-width: 31%;
  }
  @media (max-width: 1150px) {
    min-width: 280px;
  }
  @media (max-width: 965px) {
    width: 48%;
  }
  @media (max-width: 820px) {
    width: 45%;
  }
  @media (max-width: 660px) {
    width: 100%;
  }
  @media (max-width: 382px) {
    min-width: 200px;
  }
}
