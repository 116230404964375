.exchangeRatePage {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 150px;
    z-index: 1;

    .introduction {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 20px;
        margin-block: 96px;

        h1 {
            font-family: "SpaceGrotesk";
            color: rgba(43, 47, 56, 1);
            font-size: 56px;
            font-weight: 500;
        }

        h2 {
            font-size: 18px;
            font-weight: 400;
            color: rgba(78, 85, 102, 1);
            max-width: 750px;
        }
    }

    .exchangeRateContainer {
        display: flex;
        align-items: flex-start;
        width: 100%;
        gap: 5%;
        max-width: 1140px;
        margin-bottom: 110px;

        .exchangeRate {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            width: 55%;

            .exchangeRateHeader {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                flex-direction: column;
                gap: 10px;
                width: 100%;
                height: 80px;
                margin: 0;

                a {
                    color: rgba(248, 0, 158, 1);
                    text-decoration: none;
                }
            }
            table {
                border-spacing: 1;
                border-collapse: collapse;
                overflow: hidden;
                width: 100%;

                thead tr {
                    border-bottom: 0.5px solid gray;

                    th {
                        font-size: 14px;
                        color: #404040;
                        text-align: left;
                        padding: 10px 0 3px 5%;

                        &:nth-child(1) {
                            border: none;
                        }
                    }
                }

                tbody tr {
                    &:last-child {
                        border: 0;
                    }
                    td {
                        font-size: 14px;
                        color: #565656;
                        border-bottom: 0.5px solid gray;
                        border-left: 0.5px solid gray;
                        padding: 10px 0 10px 5%;

                        &:nth-child(1) {
                            border-left: none;
                            // width: 100px;
                            text-align: right;
                            padding: 10px 5% 10px 0;
                            width: 200px;
                        }
                        &:nth-child(2) {
                            width: 180px;
                        }
                    }
                }
            }
        }
        .exchangeRateCalculator {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            width: 40%;
            gap: 24px;
            z-index: 10;

            .exchangeRateHeader {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                flex-direction: column;
                width: 100%;
                height: 65px;
                margin: 0;
            }

            .exchangeRateForm {
                display: flex;
                align-items: flex-end;
                justify-content: center;
                flex-direction: row;
                gap: 12px;

                .exRateInput {
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    flex-direction: column;
                    gap: 4px;

                    input {
                        width: 270px;
                        height: 42px;
                        background-color: inherit;
                        border-radius: 6px;
                        border: 1px solid #e5e5e5;
                        transition: 0s;
                        padding-left: 12px;

                        &:focus {
                            border: 1px solid #070707;
                            outline: 1px solid #070707;
                        }
                    }

                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }
                    input[type="number"] {
                        -moz-appearance: textfield;
                    }
                }
            }

            .exRateCalcDropdown {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-direction: row;
                gap: 4px;
                padding: 0 12px;
                width: 100px;
                min-width: 100px;
                height: 42px;
                background-color: inherit;
                border-radius: 6px;
                border: 1px solid #e5e5e5;
                transition: 0s;
                cursor: pointer;

                &:hover {
                    // border: 1px solid #070707;
                    background-color: #e5e5e5;

                    img {
                        rotate: 180deg;
                    }
                    .exRateCalcDropdownContent {
                        display: flex;
                        animation: opacity 1s ease;
                        opacity: 1;
                    }
                    @keyframes opacity {
                        from {
                            opacity: 0;
                        }
                        to {
                            opacity: 1;
                        }
                    }
                }

                .exRateCalcDropdownContent {
                    position: absolute;
                    top: 105%;
                    left: -0.8px;
                    display: none;
                    opacity: 0;
                    transition: 1s;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    background-color: inherit;
                    border-radius: 6px;
                    border: 1px solid #e5e5e5;
                    width: 100px;
                    background-color: #e5e5e5;
                    z-index: 10;

                    li {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        height: 40px;
                        list-style: none;
                        width: 100%;
                        border-radius: 6px;
                        cursor: pointer;

                        &:hover {
                            background-color: #d5d5d5;
                        }
                    }
                }
            }
        }

        @media (max-width: 1024px) {
            flex-direction: column;
            gap: 40px;

            .exchangeRate {
                width: 100%;

                .exchangeRateHeader {
                    height: auto;
                    margin-bottom: 10px;
                    h1 {
                        font-size: 28px;
                    }
                    p {
                        font-size: 15px;
                    }
                }

                table {
                    tbody tr {
                        td {
                            &:nth-child(1) {
                                width: auto;
                            }
                            &:nth-child(2) {
                                width: auto;
                            }
                        }
                    }
                }
            }
            .exchangeRateCalculator {
                width: 100%;

                .exchangeRateHeader {
                    height: auto;
                    margin-bottom: 5px;

                    h1 {
                        font-size: 28px;
                    }
                }
            }
        }
        @media (max-width: 450px) {
            .exchangeRate {
                .exchangeRateHeader {
                    h1 {
                        font-size: 26px;
                    }
                    p {
                        font-size: 14px;
                    }
                }

                table {
                    tbody tr {
                        td {
                            text-align: left !important;
                            padding: 10px 0 10px 5% !important;
                        }
                    }
                }
            }
            .exchangeRateCalculator {
                .exchangeRateHeader {
                    margin-bottom: 0px;

                    h1 {
                        font-size: 26px;
                    }
                }
                .exchangeRateForm {
                    width: 100%;
                    .exRateInput {
                        width: 100%;
                        input {
                            width: 100%;
                        }
                    }
                }

                .exRateCalcDropdown {
                    width: 92px;
                    min-width: 92px;

                    .exRateCalcDropdownContent {
                        width: 92px !important;
                    }
                }
            }
        }
    }

    @media (min-width: 625px) and (max-width: 1150px) {
        padding: 0 32px 0 32px;

        .introduction {
            gap: 16px;
            margin-block: 56px;

            h1 {
                font-size: 48px;
            }
            h2 {
                font-size: 16px;
                max-width: 92%;
            }
        }
    }

    @media (max-width: 744px) {
        padding: 0 24px 0 24px;

        .introduction {
            gap: 16px;
            margin-block: 40px;

            h1 {
                font-size: 40px;
                // font-weight: 600;
            }
            h2 {
                font-size: 16px;
                max-width: 100%;
            }
        }
    }
}
