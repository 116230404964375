.contactUs {
    width: 100%;
    position: relative;
    padding-top: 64px;
    padding-bottom: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(249, 249, 249, 1);
    gap: 41px;
    overflow: hidden;

    @media (max-width: 600px) {
        padding-bottom: 64px;
    }

    .ball3-1 {
        right: 0px;
        top: 475px;
        @media (max-width: 2000px) {
            right: -150px;
        }
    }

    .backgroundImage {
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background-image: linear-gradient(
                180deg,
                #f87ccb 2.63%,
                rgba(179, 72, 152, 1) 16.95%,
                rgba(97, 9, 91, 0.5) 32.08%,
                rgba(67, 11, 78, 0.5) 50.06%,
                rgba(36, 13, 64, 0.6) 70.09%,
                rgba(0, 15, 48, 0.6) 98.16%
            ),
            url("../../assets/Yerevan.png");

        background-repeat: no-repeat;
        background-position: center;
        max-width: 1140px;
        width: 90%;
        height: 380px;
        border-radius: 32px;
        font-family: "SpaceGrotesk";
        font-size: 40px;
        font-weight: 500;
        color: rgba(249, 249, 249, 1);

        @media (min-width: 230px) and (max-width: 915px) {
            font-size: 32px;
        }
        @media (min-width: 230px) and (max-width: 600px) {
            height: 213px;
            font-size: 28px;
            background-size: cover;
        }
    }

    .contacts {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
        gap: 30px;

        .contactsCard {
            display: flex;
            flex-direction: column;
            max-width: 360px;
            width: 27.8%;
            height: 194px;
            padding: 32px;
            border-radius: 32px;
            gap: 3px;
            background: white;
            box-shadow: 0 10px 40px 10px rgba(51, 51, 79, 0.06);
            z-index: 2;

            p {
                font-size: 16px;
                font-weight: 600;
                color: rgba(43, 47, 56, 1);
                margin-bottom: 5px;
            }
            span,
            a {
                font-size: 14px;
                font-weight: 400;
                color: rgba(78, 85, 102, 1);
            }
            a {
                text-decoration: none;
                font-weight: 600;
                padding-left: 3px;
            }
        }
        @media (min-width: 601px) and (max-width: 915px) {
            flex-wrap: wrap;
            gap: 16px;
            row-gap: 24px !important;
            .contactsCard {
                display: flex;
                width: 44.2%;
                max-width: 100%;
            }
            .contactsCard:nth-child(3) {
                width: 90% !important;
            }
        }
        @media (min-width: 230px) and (max-width: 600px) {
            flex-wrap: wrap;
            gap: 16px;
            row-gap: 24px !important;
            margin-bottom: 0;

            .contactsCard {
                display: flex;
                width: 90%;
                max-width: 100%;
            }
            .contactsCard:nth-child(3) {
                width: 90% !important;
            }
        }
    }
    @media (min-width: 850px) and (max-width: 1050px) {
        .contactsCard {
            height: auto !important;
        }
    }

    .alert {
        z-index: 2;
        // width: 60%;
        // max-width: 750px;
        max-width: 1100px;
        width: 90%;
        min-height: 64px;
        display: flex;
        align-items: flex-start;
        padding: 8px 12px 8px 12px;
        gap: 16px;
        background: rgba(253, 237, 237, 1);
        border: 1px solid rgba(235, 87, 87, 1);
        border-radius: 12px;
        height: auto;
        p {
            color: rgba(235, 87, 87, 1);
            font-size: 16px;
            font-weight: 400;
            text-align: left;
        }

        @media (max-width: 915px) {
            max-width: 90%;
            width: 90%;
        }
        @media (max-width: 670px) {
            p {
                font-size: 14px;
            }
        }
    }
}
