.brokerage {
    position: relative;
    overflow: hidden;

    .ball5-1 {
        left: -30px;
        top: 100px;
    }
    .ball5-2 {
        right: 0;
        top: 600px;
        @media (max-width: 2000px) {
            right: -120px;
        }
    }
    .ball5-3 {
        left: 0;
        top: 1650px;
        z-index: 0;
    }
    .ball5-4 {
        right: 0;
        bottom: 0;
        @media (max-width: 2000px) {
            right: -120px;
        }
    }
    span,
    h1,
    h2 {
        z-index: 2;
    }
    .BImg {
        margin-bottom: 30px;
        z-index: 2;
    }
    .subtitle2 {
        font-family: "SpaceGrotesk";
        font-size: 48px;
        font-weight: 500;
        color: rgba(43, 47, 56, 1);
        margin-top: 150px;
        margin-bottom: 16px;
        z-index: 2;
    }
    .subtitle3 {
        font-size: 18px;
        font-weight: 400;
        color: rgba(78, 85, 102, 1);
        margin-bottom: 104px;
        z-index: 2;
    }
    .request {
        display: flex;
        gap: 30px;
        margin-bottom: 56px;
        z-index: 2;

        @media (min-width: 601px) and (max-width: 1024px) {
            flex-wrap: wrap;
            justify-content: center;
            gap: 16px;
            row-gap: 64px !important;
            margin-bottom: 40px;

            .step {
                display: flex;
                width: 44.2%;
                max-width: 100%;
            }
            .step:nth-child(3) {
                width: 90% !important;
            }
        }
        @media (min-width: 230px) and (max-width: 600px) {
            flex-wrap: wrap;
            justify-content: center;
            gap: 16px;
            row-gap: 64px !important;
            margin-bottom: 40px;

            .step {
                display: flex;
                width: 90%;
                max-width: 100%;
            }
            .step:nth-child(3) {
                width: 90% !important;
            }
        }
    }
    .step {
        position: relative;
        width: 360px;
        height: 193px;
        padding: 32px;
        border-radius: 32px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 16px;
        background: white;
        box-shadow: 0 10px 40px 10px rgba(51, 51, 79, 0.06);
        z-index: 2;

        span {
            position: absolute;
            top: -62px;
            font-size: 72px;
            font-weight: 600;
            color: rgba(250, 77, 187, 0.7);
        }
        .title {
            font-family: "SpaceGrotesk";
            font-size: 24px;
            font-weight: 500;
            color: rgba(43, 47, 56, 1);
        }
        p {
            font-size: 16px;
            font-weight: 400;
            color: rgba(78, 85, 102, 1);
        }
    }
    button {
        margin-top: 16px;
        background: rgba(250, 77, 187, 1);
        width: 261px;
        height: 52px;
        border-radius: 12px;
        gap: 8px;
        font-size: 16px;
        font-weight: 600;
        color: rgba(249, 249, 249, 1);
        z-index: 2;
    }
    .whatIsABrokerageAccount {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 9.3%;
        margin-top: 180px;
        margin-bottom: 120px;
        z-index: 2;

        .whatIs {
            width: 555px;

            p {
                font-family: "SpaceGrotesk";
                font-size: 48px;
                font-weight: 500;
                color: rgba(43, 47, 56, 1);
                margin-bottom: 30px;
            }
            span {
                font-size: 16px;
                font-weight: 400;
                color: rgba(78, 85, 102, 1);
            }

            a {
                color: #f8009e;
                margin-left: 7px;
            }
        }
    }
    .whatCanYouDo {
        z-index: 2;

        .termsCard {
            ul {
                width: 510px;
                margin-top: 40px;
            }
        }
    }

    @media (min-width: 230px) and (max-width: 1150px) {
        padding: 56px 32px;
        .ball5-2 {
            display: none;
        }
        .ball5-3 {
            display: none;
        }
        h1 {
            text-align: center;
        }
        h2 {
            text-align: center;
        }
        .subtitle2 {
            font-size: 40px;
            margin-top: 56px;
            margin-bottom: 16px;
            text-align: center;
        }
        .subtitle3 {
            font-size: 16px;
            margin-bottom: 97px;
            text-align: center;
        }
        .whatIsABrokerageAccount {
            width: 100%;
            display: flex;
            flex-wrap: wrap-reverse;
            align-items: center;
            justify-content: center;
            gap: 150px;
            row-gap: 80px;
            margin-bottom: 0px;

            .whatIs {
                width: 100%;
                text-align: center;

                p {
                    font-family: "SpaceGrotesk";
                    font-size: 48px;
                    font-weight: 500;
                    color: rgba(43, 47, 56, 1);
                    margin-bottom: 30px;
                }
                span {
                    font-size: 16px;
                    font-weight: 400;
                    color: rgba(78, 85, 102, 1);
                }
            }
        }
        .whatCanYouDo {
            flex-wrap: wrap;
            .termsCard {
                align-items: center;
                padding-left: 70px;
            }
        }
    }
    @media (min-width: 230px) and (max-width: 750px) {
        padding: 40px 32px 64px 24px;

        .subtitle2 {
            font-size: 28px;
        }
        .subtitle3 {
            margin-bottom: 81px;
        }
        .whatIsABrokerageAccount {
            .whatIs {
                p {
                    font-family: "SpaceGrotesk";
                    font-size: 28px;
                    font-weight: 500;
                    color: rgba(43, 47, 56, 1);
                    margin-bottom: 30px;
                }
                span {
                    font-size: 14px;
                    font-weight: 400;
                    color: rgba(78, 85, 102, 1);
                }
            }
        }
        .whatCanYouDo {
            flex-wrap: wrap;
            .termsCard {
                align-items: center;
                padding-left: 70px;
            }
        }
    }
    @media (min-width: 230px) and (max-width: 540px) {
        padding: 40px 32px 64px 24px;

        .ball5-1 {
            display: none;
        }
        .ball5-2 {
            display: none;
        }
        .ball5-3 {
            display: none;
        }
        .ball5-4 {
            display: none;
        }
        .whatIsABrokerageAccount {
            margin-top: 130px;
        }
        .whatIs {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        .whatCanYouDo {
            width: 100%;
            margin-top: 130px;

            flex-wrap: wrap;
            .termsCard {
                width: 100%;
                padding-left: 0px;
                ul {
                    width: 100%;
                }
            }
        }
    }
    @media (min-width: 400px) and (max-width: 540px) {
        .BImg {
            width: 500px;
        }
        .brokerageImgs {
            width: 350px;
        }
    }
    @media (max-width: 400px) {
        .BImg {
            width: 450px;
        }
        .brokerageImgs {
            width: 250px;
        }
    }
}
