.card {
    width: 555px;
    height: 362px;
    border-radius: 32px;
    background: white;
    padding: 32px;
    position: relative;
    overflow: hidden;

    p {
        font-family: "SpaceGrotesk";
        margin-top: 22px;
        margin-bottom: 16px;
        font-size: 28px;
        font-weight: 500;
        color: rgba(43, 47, 56, 1);
    }
    span {
        font-size: 16px;
        font-weight: 400;
        color: rgba(78, 85, 102, 1);
    }

    .buttons {
        display: flex;
        gap: 8px;
        position: absolute;
        bottom: 32px;
        right: 32px;

        button {
            right: 0;
            bottom: 0;
            height: 52px;
            border-radius: 12px;
            font-size: 16px;
            font-weight: 600;
            &:nth-child(1) {
                width: 108px;
                color: rgba(248, 0, 158, 1);
                border: 1px solid rgba(248, 0, 158, 1);
            }
            &:nth-child(2) {
                width: 174px;
                background: rgba(248, 0, 158, 1);
                color: rgba(249, 249, 249, 1);
            }
        }
    }
    @media (min-width: 916px) and (max-width: 1150px) {
        max-width: 468px;
        width: 50%;
        height: 363px;
    }
    @media (min-width: 530px) and (max-width: 915px) {
        width: 100%;
        height: 335px;

        p {
            font-size: 24px;
        }

        .buttons {
            justify-content: center;
            width: 100%;
            right: 0px;

            button {
                width: 45% !important;
                right: 0;
                bottom: 0;
            }
        }
    }
    @media (min-width: 230px) and (max-width: 530px) {
        width: 100%;
        height: 485px;

        p {
            font-size: 24px;
        }

        .buttons {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            right: 0px;
            padding: 0 32px 0 32px;

            button {
                width: 100% !important;
                right: 0;
                bottom: 0;
            }
        }
    }
}
