.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;
    background: #f2f3f5;

    .ball1 {
        left: 0;
        top: 21px;
    }
    .ball2 {
        right: 0;
        top: 700px;
        @media (max-width: 2000px) {
            right: -180px;
        }
    }
    .ball3 {
        left: 0;
        top: 1650px;
        z-index: 0;
    }
    .ball4 {
        right: 0;
        top: 2350px;
        @media (max-width: 2000px) {
            right: -150px;
        }
    }

    .home {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 150px;
        z-index: 1;

        .introduction {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            gap: 20px;
            margin-block: 96px;

            h1 {
                font-family: "SpaceGrotesk";
                color: rgba(43, 47, 56, 1);
                font-size: 56px;
                font-weight: 500;
            }

            h2 {
                font-size: 18px;
                font-weight: 400;
                color: rgba(78, 85, 102, 1);
                max-width: 750px;
            }

            .downloadFromStoreH {
                width: auto;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                gap: 14px;

                a {
                    img {
                        height: 44px;
                    }
                    position: relative;
                    overflow: hidden;
                    &:before {
                        content: "";
                        position: absolute;
                        height: 150%;
                        width: 25px;
                        transform: rotate(20deg);
                        background-color: rgba(255, 255, 255, 0.2);
                        box-shadow: 0px 0px 5px 5px rgba(255, 255, 255, 0.2);
                        top: -25%;
                        left: -50px;
                        transition: 0s;
                    }
                    &:hover {
                        transform: scale(1.1);
                        &:before {
                            transition: 0.1s;
                            transition: left 500ms ease;
                            left: 170px;
                        }
                    }
                }

                @media (max-width: 750px) {
                    a {
                        img {
                            height: 36px;
                        }

                        &:hover {
                            transform: scale(1.04);
                        }
                    }
                }

                @media (max-width: 300px) {
                    flex-direction: column;
                    gap: 4px;
                }
            }

            button {
                width: 215px;
                height: 52px;
                margin-top: 8px;
                border-radius: 12px;
                background: rgba(248, 0, 158, 1);
                color: rgba(249, 249, 249, 1);
                font-size: 16px;
                font-weight: 600;
            }
        }
        .subtitle {
            font-family: "SpaceGrotesk";
            font-size: 48px;
            font-weight: 500;
            color: rgba(43, 47, 56, 1);
            margin-top: 160px;
            margin-bottom: 56px;
        }
        .loginPart {
            display: flex;
            gap: 30px;
        }
        .about {
            z-index: 10;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            width: 1140px;
            height: 190px;
            padding: 32px 80px 32px 80px;
            border-radius: 32px;
            background: rgba(0, 15, 48, 1);
            margin-top: 128px;

            div {
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                flex-direction: column;

                p {
                    font-size: 56px;
                    font-weight: 300;
                    color: rgba(250, 77, 187, 1);
                    margin-bottom: 8px;
                }
                span {
                    font-size: 16px;
                    font-weight: 400;
                    color: rgba(249, 249, 249, 1);
                }
            }
        }
        .subtitle2 {
            font-family: "SpaceGrotesk";
            font-size: 48px;
            font-weight: 500;
            color: rgba(43, 47, 56, 1);
            margin-top: 150px;
            margin-bottom: 16px;
        }
        .subtitle3 {
            font-size: 18px;
            font-weight: 400;
            color: rgba(78, 85, 102, 1);
            margin-bottom: 104px;
        }
        .request {
            display: flex;
            gap: 30px;
            margin-bottom: 144px;
        }

        .exchangeRateContainer {
            display: flex;
            align-items: flex-start;
            width: 100%;
            gap: 5%;
            max-width: 1140px;
            margin-bottom: 110px;

            .exchangeRate {
                display: flex;
                align-items: flex-start;
                justify-content: center;
                flex-direction: column;
                width: 55%;

                .exchangeRateHeader {
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    flex-direction: column;
                    gap: 10px;
                    width: 100%;
                    height: 120px;
                    margin: 0;

                    a {
                        color: rgba(248, 0, 158, 1);
                        text-decoration: none;
                    }
                }
                table {
                    border-spacing: 1;
                    border-collapse: collapse;
                    overflow: hidden;
                    width: 100%;

                    thead tr {
                        border-bottom: 0.5px solid gray;

                        th {
                            font-size: 14px;
                            color: #404040;
                            text-align: left;
                            padding: 10px 0 3px 5%;

                            &:nth-child(1) {
                                border: none;
                            }
                        }
                    }

                    tbody tr {
                        &:last-child {
                            border: 0;
                        }
                        td {
                            font-size: 14px;
                            color: #565656;
                            border-bottom: 0.5px solid gray;
                            border-left: 0.5px solid gray;
                            padding: 10px 0 10px 5%;

                            &:nth-child(1) {
                                border-left: none;
                                // width: 100px;
                                text-align: right;
                                padding: 10px 5% 10px 0;
                                width: 200px;
                            }
                            &:nth-child(2) {
                                width: 180px;
                            }
                        }
                    }
                }
            }
            .exchangeRateCalculator {
                display: flex;
                align-items: flex-start;
                justify-content: center;
                flex-direction: column;
                width: 40%;
                gap: 24px;
                z-index: 10;

                .exchangeRateHeader {
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    flex-direction: column;
                    width: 100%;
                    height: 105px;
                    margin: 0;
                }

                .exchangeRateForm {
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                    flex-direction: row;
                    gap: 12px;

                    .exRateInput {
                        display: flex;
                        align-items: flex-start;
                        justify-content: flex-start;
                        flex-direction: column;
                        gap: 4px;

                        input {
                            width: 270px;
                            height: 42px;
                            background-color: inherit;
                            border-radius: 6px;
                            border: 1px solid #e5e5e5;
                            transition: 0s;
                            padding-left: 12px;

                            &:focus {
                                border: 1px solid #070707;
                                outline: 1px solid #070707;
                            }
                        }

                        input::-webkit-outer-spin-button,
                        input::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }
                        input[type="number"] {
                            -moz-appearance: textfield;
                        }
                    }
                }

                .exRateCalcDropdown {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex-direction: row;
                    gap: 4px;
                    padding: 0 12px;
                    width: 100px;
                    min-width: 100px;
                    height: 42px;
                    background-color: inherit;
                    border-radius: 6px;
                    border: 1px solid #e5e5e5;
                    transition: 0s;
                    cursor: pointer;

                    &:hover {
                        // border: 1px solid #070707;
                        background-color: #e5e5e5;

                        img {
                            rotate: 180deg;
                        }
                        .exRateCalcDropdownContent {
                            display: flex;
                            animation: opacity 1s ease;
                            opacity: 1;
                        }
                        @keyframes opacity {
                            from {
                                opacity: 0;
                            }
                            to {
                                opacity: 1;
                            }
                        }
                    }

                    .exRateCalcDropdownContent {
                        position: absolute;
                        top: 105%;
                        left: -0.8px;
                        display: none;
                        opacity: 0;
                        transition: 1s;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        background-color: inherit;
                        border-radius: 6px;
                        border: 1px solid #e5e5e5;
                        width: 100px;
                        background-color: #e5e5e5;
                        z-index: 10;

                        li {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            height: 40px;
                            list-style: none;
                            width: 100%;
                            border-radius: 6px;
                            cursor: pointer;

                            &:hover {
                                background-color: #d5d5d5;
                            }
                        }
                    }
                }
            }

            @media (max-width: 1024px) {
                flex-direction: column;
                gap: 40px;

                .exchangeRate {
                    width: 100%;

                    .exchangeRateHeader {
                        height: auto;
                        margin-bottom: 10px;
                        h1 {
                            font-size: 28px;
                        }
                        p {
                            font-size: 15px;
                        }
                    }

                    table {
                        tbody tr {
                            td {
                                &:nth-child(1) {
                                    width: auto;
                                }
                                &:nth-child(2) {
                                    width: auto;
                                }
                            }
                        }
                    }
                }
                .exchangeRateCalculator {
                    width: 100%;

                    .exchangeRateHeader {
                        height: auto;
                        margin-bottom: 5px;

                        h1 {
                            font-size: 28px;
                        }
                    }
                }
            }
            @media (max-width: 450px) {
                .exchangeRate {
                    .exchangeRateHeader {
                        h1 {
                            font-size: 26px;
                        }
                        p {
                            font-size: 14px;
                        }
                    }

                    table {
                        tbody tr {
                            td {
                                text-align: left !important;
                                padding: 10px 0 10px 5% !important;
                            }
                        }
                    }
                }
                .exchangeRateCalculator {
                    .exchangeRateHeader {
                        margin-bottom: 0px;

                        h1 {
                            font-size: 26px;
                        }
                    }
                    .exchangeRateForm {
                        width: 100%;
                        .exRateInput {
                            width: 100%;
                            input {
                                width: 100%;
                            }
                        }
                    }

                    .exRateCalcDropdown {
                        width: 92px;
                        min-width: 92px;

                        .exRateCalcDropdownContent {
                            width: 92px !important;
                        }
                    }
                }
            }
        }

        @media (min-width: 625px) and (max-width: 1150px) {
            padding: 0 32px 0 32px;

            .introduction {
                gap: 16px;
                margin-block: 56px;

                h1 {
                    font-size: 48px;
                }
                h2 {
                    font-size: 16px;
                    max-width: 92%;
                }
            }
            .subtitle {
                font-size: 40px;
                margin-top: 110px;
                margin-bottom: 40px;
            }
            .loginPart {
                gap: 24px;
            }
            .about {
                width: 100%;
                max-width: 960px;
                margin-top: 110px;
            }
            .subtitle2 {
                font-size: 40px;
                margin-top: 110px;
            }
            .subtitle3 {
                font-size: 16px;
                margin-bottom: 94px;
            }
            .request {
                align-items: center;
                justify-content: center;
                gap: 24px;
                margin-bottom: 56px;
            }
        }
        @media (min-width: 531px) and (max-width: 744px) {
            padding: 0 24px 0 24px;

            .introduction {
                gap: 16px;
                margin-block: 40px;

                h1 {
                    font-size: 40px;
                    // font-weight: 600;
                }
                h2 {
                    font-size: 16px;
                    max-width: 100%;
                }
            }
            .subtitle {
                font-size: 28px;
                margin-top: 80px;
                margin-bottom: 40px;
                font-weight: 600;
            }
            .loginPart {
                gap: 24px;
            }
            .about {
                align-items: flex-start;
                justify-content: flex-start;
                flex-direction: column;
                width: 100%;
                height: 271px;
                padding: 32px;
                margin-top: 80px;

                div {
                    flex-direction: row;

                    p {
                        font-size: 48px;
                        margin-right: 24px;
                    }
                }
            }
            .subtitle2 {
                font-size: 28px;
                margin-top: 80px;
                font-weight: 600;
                text-align: center;
            }
            .subtitle3 {
                font-size: 16px;
                margin-bottom: 94px;
                text-align: center;
            }
            .request {
                align-items: center;
                justify-content: center;
                gap: 24px;
                margin-bottom: 56px;
            }
        }
        @media (max-width: 530px) {
            padding: 0 24px 0 24px;

            .homeImg {
                width: 550px;
            }

            .introduction {
                gap: 16px;
                margin-block: 40px;

                h1 {
                    font-size: 40px;
                }
                h2 {
                    font-size: 16px;
                    max-width: 100%;
                }
            }
            .subtitle {
                font-size: 28px;
                margin-top: 80px;
                margin-bottom: 40px;
                font-weight: 600;
            }
            .loginPart {
                flex-direction: column;
                gap: 24px;
            }
            .about {
                align-items: flex-start;
                justify-content: flex-start;
                flex-direction: column;
                width: 100%;
                height: 271px;
                padding: 32px;
                margin-top: 80px;
                gap: 24px;

                div {
                    flex-direction: row;

                    p {
                        font-size: 32px;
                        margin-right: 24px;
                    }
                    div {
                        display: flex;
                        flex-direction: column;
                    }
                    span {
                        font-size: 14px;
                    }
                }
            }
            .subtitle2 {
                font-size: 28px;
                margin-top: 80px;
                font-weight: 600;
                text-align: center;
            }
            .subtitle3 {
                font-size: 16px;
                margin-bottom: 94px;
                text-align: center;
            }
            .request {
                display: flex;
                flex-wrap: wrap;
                gap: 16px !important;
                row-gap: 57px !important;
                margin-bottom: 64px;
            }
        }
        @media (max-width: 400px) {
            .homeImg {
                width: 500px;
            }
        }
        @media (min-width: 530px) and (max-width: 915px) {
            .loginPart {
                flex-direction: column;
                gap: 24px;
            }
            .request {
                display: flex;
                flex-wrap: wrap;
                gap: 16px !important;
                row-gap: 57px !important;
                margin-bottom: 64px;
            }
        }
    }

    .getStarted {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 460px;
        gap: 40px;
        background: rgba(0, 15, 48, 1);

        p {
            font-family: "SpaceGrotesk";
            color: rgba(249, 249, 249, 1);
            font-size: 40px;
            font-weight: 500;
        }
        button {
            background: rgba(250, 77, 187, 1);
            width: 215px;
            height: 52px;
            border-radius: 12px;
            gap: 8px;
            font-size: 16px;
            font-weight: 600;
            color: rgba(249, 249, 249, 1);
        }

        @media (max-width: 744px) {
            height: 447px;
            p {
                font-size: 28px;
            }
        }
    }
}
