.accordionItems {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 16px;
    height: 60px;
    transition: 0.1s;
    &:hover {
        background: white;
    }
    img {
        margin-left: 2px;
    }
    .linkImg {
        width: 26px;
    }
    p {
        font-size: 16px;
        font-weight: 600;
        color: rgba(43, 47, 56, 1);
        max-width: 85%;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
    }
}
