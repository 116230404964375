.teamCard {
    display: flex;
    flex-direction: column;
    width: 360px;
    height: 320px;
    border-radius: 32px;
    padding: 32px;
    position: relative;
    background: white;

    .portrait {
        width: 80px;
        height: 80px;
        border-radius: 16px;
        // background-image: linear-gradient(135deg, rgba(248, 211, 235, 1), rgba(187, 207, 250, 1));
        position: absolute;
        top: -60px;
        border-radius: 100%;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    p {
        font-family: "SpaceGrotesk";
        font-size: 20px;
        font-weight: 500;
        color: rgba(43, 47, 56, 1);
        margin-top: 0px;
        margin-bottom: 8px;
    }
    span {
        font-size: 14px;
        font-weight: 400;
        color: rgba(78, 85, 102, 1);
    }
    .info {
        margin-top: 24px;
        font-size: 16px;
    }
}
