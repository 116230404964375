.research {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;
    background: #f2f3f5;
    padding: 62px 32px 150px 32px;
    // min-height: 70vh;

    span {
        font-size: 14px;
        font-weight: 600;
        color: rgba(78, 85, 102, 1);
    }
    h1 {
        text-align: center;
        font-family: "SpaceGrotesk";
        color: rgb(43, 47, 56);
        font-size: 18px;

        max-width: 700px;
        // font-weight: 400;
        // padding-bottom: 30px;
        // line-height: 28px;

        // width: 560px;
        font-size: 18px;
        font-weight: 400;
        padding-block: 2px 30px;
        line-height: 24px;
        // color: rgb(78, 85, 102);

        @media (max-width: 450px) {
            font-size: 16px !important;
            line-height: 22px;
        }
    }

    .wtb-search-filter {
        width: 100%;
        max-width: 500px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        gap: 10px;
        z-index: 12;
        padding: 0px;
        transition: 0.2s;
        user-select: none;
        margin: 0;
        transition: 0s;

        .wtb-search-icon {
            position: absolute;
            left: 14px;
            width: 22px;
            filter: grayscale(100%) brightness(0);
            z-index: 20;
            cursor: pointer;
            transition: all 0.2s;
        }
        input {
            padding-left: 44px;
            height: 40px;
            flex-grow: 1;
            border-radius: 23px;
            font-size: 15px !important;
            transition: 0.2s;
            z-index: 15;
            color: rgba(0, 0, 0, 0.85);

            &::placeholder {
                color: rgba(0, 0, 0, 0.5);
                font-size: 15px !important;
            }
            &:focus {
                background: rgba(255, 255, 255, 0.9);
                outline: none;
            }
        }

        .filter-categories {
            width: 40px;
            min-width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            top: 200px;
            right: 24px;
            border-radius: 24px;
            transition: 0.2s;
            z-index: 15;
            color: black;

            img {
                width: 22px;
                background: initial !important;
            }
        }
        .filter-categories-dropdown {
            width: 40%;
            padding: 12px 16px;
            position: absolute;
            top: 50px;
            right: 0px;
            border-radius: 6px;
            background: rgba(255, 255, 255, 0.9) !important;
            transition: 0.2s;
            z-index: 15;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 4px;

            @media (max-width: 350px) {
                width: calc(100% - 24px);
            }
        }

        .filter-categories,
        .filter-categories-dropdown,
        input {
            background: rgba(255, 255, 255, 0.5);
            backdrop-filter: blur(3px);
            -webkit-backdrop-filter: blur(3px);
            box-sizing: content-box;
            box-shadow: 0 1px 6px -2px rgba(0, 0, 0, 0.4);

            &:hover {
                background: rgba(255, 255, 255, 0.9) !important;
            }
        }

        @media (max-width: 770px) {
            transition: 0.2s;
            max-width: 100% !important;
        }
    }

    // .wtb-search-filter-stuck {
    //     position: fixed;
    //     top: 0px;
    //     margin-top: 70px;
    //     transition: 0.3s;
    // }

    .researchContainer {
        max-width: 1170px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 50px;
        gap: 25px;

        &:after {
            content: "";
            width: 365px;

            @media (max-width: 1250px) {
                width: 31.5%;
            }
            @media (max-width: 1150px) {
                display: none;
            }
        }

        &::before {
            content: "";
            width: 365px;
            order: 2;

            @media (max-width: 1250px) {
                width: 31.5%;
            }
            @media (max-width: 1150px) {
                display: none;
            }
        }
    }

    @media (max-width: 1250px) {
        padding: 62px 32px 50px 32px;
        .researchContainer {
            margin-top: 20px;
            width: 100%;
            gap: 25px;
        }
    }
}
