.loading {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100vh;
    background: rgba(255, 255, 255, 1);
    background-color: rgba(255, 255, 255, 1);
    z-index: 100000;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    animation: bacgroundL 0.18s linear;

    @keyframes bacgroundL {
        from {
            opacity: 0.7;
        }
        to {
            opacity: 1;
        }
    }

    .loader-mobile {
        display: block;
    }
    .loader {
        display: none;
    }

    p {
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        color: rgba(248, 0, 158, 1);
    }

    @media (max-width: 750px) {
        .loader-mobile {
            display: none;
        }
        .loader {
            position: absolute;
            display: block;
        }
        p {
            font-size: 10px;
            font-weight: 400;
            color: rgba(122, 132, 153, 1);
        }
    }
}

.loader-mobile {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background:
        radial-gradient(farthest-side, #ef0199 94%, #0000) top/4px 4px no-repeat,
        conic-gradient(#0000 30%, #ef0199);
    -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 4px), #000 0);
    animation: loader 1.4s infinite linear;
}
.loader {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background:
        radial-gradient(farthest-side, #ef0199 94%, #0000) top/9px 9px no-repeat,
        conic-gradient(#0000 30%, #ef0199);
    -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 9px), #000 0);
    animation: loader 1.4s infinite linear;
}
@keyframes loader {
    100% {
        transform: rotate(1turn);
    }
}
