.globalMarkets {
    text-align: center;
    padding-bottom: 120px;
    position: relative;
    overflow: hidden;

    .ball4-1 {
        left: -50px;
        top: 0;
    }
    .ball4-2 {
        right: 0px;
        bottom: -350px;
        @media (max-width: 2000px) {
            right: -50px;
        }
    }

    span {
        font-size: 14px;
        font-weight: 600;
        color: rgba(78, 85, 102, 1);
        z-index: 2;
    }
    h1 {
        max-width: 700px;
        z-index: 2;
    }
    h2 {
        max-width: 700px;
        margin-bottom: 56px;
        z-index: 2;
    }
    .mapImg {
        margin-bottom: 56px;
        z-index: 2;
        width: 90%;
        max-width: 1140px;
    }
    .mapImgMobile{
        display: none;
    }
    .marketInfo {
        max-width: 1135px;
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 2;
    }
    @media (max-width: 450px) {
        .mapImg {
            display: none;
            margin-bottom: 40px;
            width: 85%;
        }
        .mapImgMobile{
            display: block;
        }
        .marketInfo {
            flex-direction: column;
            gap: 24px;
        }
    }
}
