.cookies {
    width: 520px;
    position: fixed;
    left: 16px;
    bottom: 16px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.5);
    z-index: 1000;
    padding: 32px 24px;
    backdrop-filter: blur(5px);
    box-shadow: 3px 3px 15px 0px #5a5a5a70;
    gap: 15px;
    user-select: none;
    animation: cookies 2s ease;

    div {
        width: 100%;
        display: flex;
        align-items: start;
        justify-content: space-between;
        p {
            width: 75%;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
            color: #4e5566;
        }
    }
    button {
        width: 150px;
        height: 34px;
        border-radius: 34px;
        text-align: center;
        padding: 0 10px;
        line-height: 12px;
        transition: 0.5s;
        cursor: pointer;
        overflow: hidden;

        &:nth-child(1) {
            background-color: rgba(128, 128, 128, 0.135);

            &:hover {
                background-color: rgba(128, 128, 128, 0.3);
            }
        }
        &:nth-child(2) {
            background-color: rgba(252, 153, 216, 1);

            &:hover {
                background-color: rgb(255, 138, 212);
            }
        }
        &:nth-child(3) {
            background-color: rgba(248, 0, 158, 0.7);

            &:hover {
                background-color: rgba(248, 0, 157, 0.9);
            }
        }
    }

    @media (max-width: 1150px) {
        width: 45%;
        padding: 32px 24px;

        div {
            p {
                width: 75%;
                font-size: 14px;
                line-height: 22px;
                text-align: left;
                color: #4e5566;
            }
        }
        button {
            width: 30%;
            height: 34px;
            border-radius: 34px;
            padding: 0 10px;
        }
    }
    @media (max-width: 880px) {
        width: 60%;
        padding: 26px 18px;

        div {
            p {
                width: 75%;
                font-size: 14px;
                line-height: 20px;
                text-align: left;
                color: #4e5566;
            }
            img {
                width: 20%;
            }
        }
        button {
            width: 32%;
        }
    }
    @media (max-width: 620px) {
        width: 90%;
        padding: 20px 18px;
        left: 50%;
        transform: translate(-50%);

        button {
            width: 32%;
            padding: 0px;
        }
    }
}
@keyframes cookies {
    0% {
        opacity: 0;
    }
    30% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
