.globalMarketCard {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;

    span {
        font-family: "SpaceGrotesk";
        font-size: 48px;
        font-weight: 500;
        color: rgba(250, 77, 187, 1);
    }
    p {
        font-size: 18px;
        font-weight: 400;
        color: rgba(78, 85, 102, 1);
    }

    @media (min-width: 451px) and (max-width: 900px) {
        flex-direction: column;
        gap: 0px;

        span {
            font-size: 40px;
        }
        p {
            font-size: 16px;
        }
    }
    @media  (max-width: 450px) {
        span {
            font-size: 28px;
        }
        p {
            font-size: 14px;
        }
    }
}
