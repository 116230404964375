.documentCard {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    max-width: 360px;
    width: 32%;
    height: 184px;
    border-radius: 32px;
    gap: 16px;
    background: white;
    box-shadow: 0px 10px 40px 10px rgba(51, 51, 79, 0.06);

    .documentImg {
        width: 80px;
        height: 80px;
        border-radius: 14px;
        background-image: url("../../assets/Document1.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-color: rgba(242, 243, 245, 1);
    }
    p {
        font-size: 16px;
        font-weight: 600;
        color: rgba(43, 47, 56, 1);
        text-align: center;
        padding: 0 16px;
    }
    &:active {
        background: rgba(255, 242, 250, 1);
    }
    &:active .documentImg {
        background-color: rgba(254, 230, 245, 1);
    }
    &:hover p {
        transition: 0.1s;
        color: rgba(248, 0, 158, 1);
    }
    &:hover .documentImg {
        transition: 0.1s;
        background-image: url("../../assets/Document2.svg");
    }
}
