:root {
    --default: rgb(249, 249, 249);
    --dfHover: rgba(248, 0, 158, 1);
    --primary: rgba(43, 47, 56, 1);
    // --trans-dur: 0.3s;
    // --dd: var(--primary1);
}
.containerNavigation {
    display: flex;
    position: relative;
}
.fixedNav {
    position: fixed !important;
}
.navigation {
    position: relative;
    width: 100%;
    height: 98px;
    background: rgba(0, 15, 48, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 110;

    .logoMobile {
        display: none;
    }

    @media (min-width: 1400px) and (max-width: 1525px) {
        padding: 0 6.5% 0 6.5%;
        justify-content: space-between;

        .nav-middle {
            width: 55% !important;
            // background-color: red;
        }
    }
    @media (min-width: 1150px) and (max-width: 1400px) {
        padding: 0 5% 0 5%;
        justify-content: space-between;

        .logo {
            width: 230px;
            height: 27.5px;
        }
        .nav-middle {
            width: auto !important;
        }
    }
    @media (min-width: 750px) and (max-width: 1150px) {
        padding: 0 32px 0 32px;
        height: 84px;
        justify-content: space-between;

        .logo {
            width: 200px;
            height: 24px;
        }
    }
    @media (max-width: 750px) {
        padding: 0 24px 0 24px;
        height: 84px;
        justify-content: space-between;

        .logo {
            display: none;
        }
        .logoMobile {
            display: block;
        }
    }

    .nav-middle {
        width: 44%;
        justify-content: center;
        @media (max-width: 1150px) {
            width: auto;
        }
    }
    ul {
        list-style-type: none;
        display: flex;
        align-items: center;
        gap: 32px;
        height: 100%;

        li {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            cursor: pointer;
            color: var(--default);

            &:hover {
                color: var(--dfHover);
            }

            .navLink {
                transition: 0s;
                text-decoration: none;
                color: var(--primary);

                &:hover {
                    transition: 0s;
                    font-weight: 600;
                }
            }
        }
        .language {
            padding-block: 28px;
            padding-right: 50px;
            background-image: url("../../assets/Language.svg");
            background-size: 25px;
            background-position: left;
            background-repeat: no-repeat;
            width: 24px;
            height: 24px;
            margin-left: 8px;
            margin-top: 2px;
            &:hover {
                background-image: url("../../assets/Language2.svg");
            }

            .languageH {
                display: none;
                flex-direction: column;
                align-items: flex-start;
                background-color: white;
                position: absolute;
                list-style-type: none;
                width: 194px;
                height: 175px;
                top: 90%;
                left: 0;
                padding: 32px;
                border-radius: 12px;
                gap: 24px;

                li {
                    display: flex;
                    align-items: center;
                    justify-content: baseline;
                    gap: 12px;
                    padding: 0;
                    color: var(--primary);

                    img {
                        width: 16px;
                        height: 16px;
                    }

                    &:hover {
                        transition: 0s;
                        font-weight: 600;
                    }
                }

                @media (max-width: 750px) {
                    left: -35px !important;
                }
            }
            &:hover .languageH {
                display: flex;
            }
        }
        .startInvesting {
            min-width: 183px;

            .startInvestingH {
                display: none;
                flex-direction: column;
                align-items: flex-start;
                background-color: white;
                position: absolute;
                list-style-type: none;
                width: 220px;
                height: auto;
                top: 90%;
                left: 0;
                padding: 32px;
                border-radius: 12px;
                gap: 24px;
                box-shadow:
                    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
                    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
                li {
                    padding: 0;
                    color: var(--primary);

                    &:hover {
                        transition: 0s;
                        font-weight: 600;
                    }
                }
            }

            &:hover .startInvestingH {
                display: flex;
            }
        }
        .plans {
            .plansH {
                display: none;
                flex-direction: column;
                align-items: flex-start;
                background-color: white;
                position: absolute;
                list-style-type: none;
                width: 180px;
                height: auto;
                top: 90%;
                left: 0;
                padding: 24px 32px;
                border-radius: 12px;
                gap: 24px;
                box-shadow:
                    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
                    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;

                li {
                    padding: 0;

                    color: var(--primary);

                    &:hover {
                        transition: 0s;
                        font-weight: 500;
                    }
                }
            }

            &:hover .plansH {
                display: flex;
            }
        }
        .company {
            .companyH {
                display: none;
                flex-direction: column;
                align-items: flex-start;
                background-color: white;
                position: absolute;
                list-style-type: none;
                width: 210px;
                height: auto;
                top: 90%;
                left: 0;
                padding: 32px;
                border-radius: 12px;
                gap: 24px;
                box-shadow:
                    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
                    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;

                li {
                    padding: 0;

                    color: var(--primary);

                    &:hover {
                        transition: 0s;
                        font-weight: 500;
                    }
                }
            }
            &:hover .companyH {
                display: flex;
            }
        }
        .nContactUs {
            .nContactUsH {
                display: none;
                flex-direction: column;
                align-items: flex-start;
                background-color: white;
                position: absolute;
                list-style-type: none;
                width: 190px;
                height: auto;
                top: 90%;
                left: 0;
                padding: 24px 32px;
                border-radius: 12px;
                gap: 24px;
                box-shadow:
                    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
                    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;

                li {
                    padding: 0;

                    color: var(--primary);

                    &:hover {
                        transition: 0s;
                        font-weight: 500;
                    }
                }
            }

            &:hover .nContactUsH {
                display: flex;
            }
        }

        .legalActsLink {
            position: relative;

            .legalActsH {
                visibility: hidden;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                background-color: white;
                position: absolute;
                list-style-type: none;
                width: 250px;
                height: auto;
                top: -8px;
                left: 110%;
                padding: 22px;
                border-radius: 10px;
                gap: 20px;
                box-shadow: 0px 0px 30px 0px #0000001a;

                li {
                    padding: 0;
                    color: var(--primary);

                    &:hover {
                        transition: 0s;
                        font-weight: 500;
                    }
                }
            }

            &:hover .legalActsH {
                visibility: visible;
            }
        }

        .menu {
            display: none;
        }
        .close {
            animation: Close 0.8s linear;
        }
        @keyframes Close {
            from {
                // rotate: 0deg;
                opacity: 0;
            }
            to {
                // rotate: 180deg;
                opacity: 1;
            }
        }

        @media (max-width: 1150px) {
            padding: 0;
            gap: 10px;

            li {
                display: none;
            }
            .startInvesting {
                display: none;
            }
            .company {
                display: none;
            }
            .language {
                margin-right: 20px;
                padding-right: 0px;
                display: block;
                background-position: left;
                // background-size: 24px;

                .languageH {
                    padding: 16px;
                    display: none;
                    width: auto;
                    height: auto;
                    top: 56px;
                    gap: 20px;
                    left: -20px;

                    @media (max-width: 450px) {
                        padding: 14px;
                        gap: 14px;
                    }

                    li {
                        gap: 10px;
                        padding: 0;
                    }
                }
            }
            .menu {
                padding-top: 3px;
                display: block;
            }
        }
    }
    .login-buttons {
        display: flex;
        align-items: center;
        justify-content: baseline;
        visibility: visible;
        gap: 24px;
        width: 275px;
        transition: 0s !important;
        z-index: 10;
        button {
            color: var(--default);

            &:nth-child(1) {
                width: 100px;
            }
            &:nth-child(1):hover {
                color: var(--dfHover);
            }
            &:nth-child(2) {
                width: 145px;
                height: 45px;
                border-radius: 12px;
                border: 1px solid var(--default);
                gap: 8px;
            }
            &:nth-child(2):hover {
                background: rgba(249, 249, 249, 0.1);
            }
        }

        @media (max-width: 1150px) {
            display: none;
        }
    }
    .hideLB {
        visibility: hidden !important;
    }
    .searchContainer {
        right: 36px;
        position: absolute;
        height: 30px;

        .search {
            input {
                cursor: pointer;
                background: none;
                border: none;
                outline: none;
                width: 28px;
                margin: 5px 0;
                z-index: 1;
                position: relative;
                line-height: 18px;
                font-size: 14px;
                -webkit-appearance: none;
                transition: 0.6s;
                color: #fff;

                & + div {
                    position: relative;
                    height: 28px;
                    width: 100%;
                    margin: -28px 0 0 0;

                    svg {
                        display: block;
                        position: absolute;
                        height: 28px;
                        width: 160px;
                        right: 0;
                        top: 0;
                        fill: none;
                        stroke: #fff;
                        stroke-width: 2px;
                        stroke-dashoffset: 212.908 + 59;
                        stroke-dasharray: 59 212.908;
                        transition: 0.1s ease-out;
                    }
                }

                &:not(:placeholder-shown),
                &:focus {
                    width: 160px;
                    padding: 0 4px;
                    cursor: text;
                    transition: 1s ease-in;

                    & + div {
                        svg {
                            stroke-dasharray: 150 212.908;
                            stroke-dashoffset: 300;
                        }
                    }
                }

                &::selection {
                    background: rgba(#fff, 0.1);
                }
                &::-moz-selection {
                    background: rgba(#fff, 0.1);
                }
            }
        }

        @media (max-width: 1700px) {
            .search {
                input {
                    & + div {
                        svg {
                            width: 140px;
                        }
                    }

                    &:not(:placeholder-shown),
                    &:focus {
                        width: 140px;
                    }
                }
            }
        }
        @media (min-width: 1526px) and (max-width: 1620px) {
            right: 24px;

            .search {
                input {
                    & + div {
                        svg {
                            width: 130px;
                        }
                    }
                    &:not(:placeholder-shown),
                    &:focus {
                        width: 130px;
                    }
                }
            }
        }
        @media (min-width: 1150px) and (max-width: 1400px) {
            right: 24px;
        }
        @media (max-width: 1150px) {
            padding-top: 3px;
            right: 143px;
            transition: 0.6s !important;

            .search {
                input {
                    & + div {
                        svg {
                            width: 145px;
                        }
                    }

                    &:not(:placeholder-shown),
                    &:focus {
                        width: 145px;
                    }
                }
            }
        }
    }
    .searchContainerMenu {
        transition: 0.4s;
        padding-top: 3px;
        right: 85px;
    }

    .searchDropdown {
        width: 250px;
        max-height: 180px;
        position: absolute;
        right: -5px;
        top: 45px;
        border-radius: 3px;
        background-color: rgb(255, 255, 255);
        padding: 0px;
        overflow-x: hidden;
        overflow-y: scroll;

        ul {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            gap: 0px;

            a {
                width: 100%;
                text-decoration: none;
            }
            li {
                width: 100%;
                display: block !important;
                text-align: left;
                color: #2b2f38;
                padding: 16px;
                transition: color 0s;
                word-break: break-all;

                &:hover {
                    background-color: rgba(250, 0, 158, 0.2);
                }
            }
        }

        @media (max-width: 450px) {
            width: 200px;
        }

        &::-webkit-scrollbar {
            width: 3px;
        }
        &::-webkit-scrollbar-track {
            background-color: #000f301e;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #f8009e;
            box-shadow: inset 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
            border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb:hover {
            background-color: #d30086;
        }
    }
}

.dropdownMenu {
    padding-top: 84px;
    z-index: 100;
    top: 0;
    bottom: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100%;
    padding-bottom: 200px;
    background-color: rgba(242, 243, 245, 1);
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }

    .ball {
        position: absolute;
        mix-blend-mode: overlay;
        z-index: -1;
        // opacity: 0.1;
    }
    .ball6-1 {
        left: 0px;
        top: 200px;
        @media (max-height: 1100px) {
            left: 0px;
            top: 80px;
        }
        @media (max-height: 950px) {
            // left: 0px;
            // top: 50px;
            display: none;
        }
    }
    .ball6-2 {
        right: -100px;
        bottom: 50px;
    }
    .accordion {
        margin-top: 30px;
        width: 100vw;
        display: flex;
        justify-content: center;
        animation: MenuItem 0.4s linear;

        .accordionItem {
            width: 92vw;
            min-height: 59px;
            padding: 0px 0px 5px 0px;
            border-bottom: 0.5px solid rgba(212, 224, 250, 1);

            .header {
                width: 92vw;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding-top: 16px;
                padding-bottom: 8px;

                p {
                    padding: 0 !important;
                    margin: 0 !important;
                    font-family: "SpaceGrotesk";
                    font-size: 24px;
                    font-weight: 600;
                    color: rgba(43, 47, 56, 1);
                    text-align: left;
                }

                .close {
                    position: relative;
                    margin-top: 5px;
                    height: 23px;
                }
                .open {
                    rotate: 180deg;
                }
            }
            .menuUl {
                list-style-type: none;

                .navLink {
                    transition: 0s !important;
                    text-decoration: none;
                    color: var(--primary);

                    li {
                        padding-block: 8px;
                        position: relative;
                        cursor: pointer;
                        color: var(--primary);
                        transition: 0s !important;
                    }
                    &:hover {
                        font-weight: 600;
                    }
                }
            }
        }
    }
    .footerMenu {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 24px;
        bottom: 0;
        width: 100vw;
        height: 100px;
        background: rgba(0, 15, 48, 1);
        animation: Menu 0.4s linear;

        button {
            width: 44%;
            height: 52px;
            border-radius: 12px;
            font-size: 16px;
            font-weight: 600;
            color: rgba(249, 249, 249, 1);

            &:nth-child(1) {
                border: 1px solid rgba(249, 249, 249, 1);
            }
            &:nth-child(2) {
                background: rgba(248, 0, 158, 1);
            }
        }

        @media (max-width: 500px) {
            align-items: center;
            justify-content: center;
            flex-direction: column;
            height: 175px;
            padding: 0 24px 0 24px;

            button {
                width: 100%;
            }
        }
    }
    .legalActsD {
        .legalActsDH {
            padding-top: 4px;
            display: none;
            flex-direction: column;
            padding-left: 6px;
            gap: 2px;
        }

        &:hover {
            .legalActsDH {
                display: flex;
            }
        }
    }
}
@keyframes Menu {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes MenuItem {
    from {
        margin-top: 300px;
        opacity: 0;
    }
    to {
        margin-top: 30px;
        opacity: 1;
    }
}

@media (min-width: 1150px) and (max-width: 1300px) {
    html:lang(am) .login-buttons {
        gap: 6px;
    }
    html:lang(am) .openAccount {
        width: 120px !important;
    }
}
