.advisory {
    position: relative;
    overflow: hidden;

    .ball6-1 {
        left: 0;
        top: -50px;
    }
    .ball6-2 {
        right: 0;
        top: 580px;
        @media (max-width: 2000px) {
            right: -180px;
        }
    }
    h1 {
        margin-bottom: 56px;
    }

    @media (max-width: 530px) {
        .imgAd {
            width: 450px;
        }
    }
    @media (max-width: 400px) {
        .imgAd {
            width: 380px;
        }
    }
}
