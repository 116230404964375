.tariffs {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 64px 32px 120px 32px;
    background: rgba(249, 249, 249, 1);

    h1 {
        font-family: "SpaceGrotesk";
        font-size: 56px;
        margin-bottom: 40px;
    }
    .tariffsSwitch {
        display: none;
        margin-bottom: 24px;

        input[type="radio"] {
            display: none;
        }

        label {
            font-size: 14px;
            font-weight: 600;
            color: rgba(43, 47, 56, 1);
            background: inherit;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border: 1px solid rgba(77, 89, 117, 1);
            width: 156px;
            height: 45px;
            transition: 0.2s;

            &:hover {
                background: rgba(77, 89, 117, 0.2);
            }
            &:active {
                background: rgba(77, 89, 117, 0.8);
                color: #fff;
            }
        }
        .label1 {
            border-radius: 32px 0 0 32px;
        }
        .label2 {
            border-left: 0px;
            border-right: 0px;
        }
        .label3 {
            border-radius: 0px 32px 32px 0px;
            &:hover {
                background: rgba(248, 0, 158, 0.2);
            }
            &:active {
                background: rgba(248, 0, 158, 0.8);
            }
        }
        #standard:checked:checked ~ .label1,
        #prime:checked:checked ~ .label2 {
            background: rgba(77, 89, 117, 1);
            color: #fff;
            &:hover {
                background: rgba(77, 89, 117, 0.9);
            }
            &:active {
                background: rgba(54, 62, 82, 1);
            }
        }
        #primePluse:checked:checked ~ .label3 {
            background: rgba(248, 0, 158, 1);
            border: 1px solid rgba(248, 0, 158, 1);

            color: #fff;

            &:hover {
                background: rgba(248, 0, 158, 0.9);
            }
            &:active {
                background: rgba(198, 3, 127, 1);
            }
        }
    }
    table {
        border-spacing: 1;
        border-collapse: collapse;
        border-radius: 36px;
        overflow: hidden;
        max-width: 1175px;
        width: 100%;

        th {
            padding: 40px 16px;
            text-align: center !important;
            font-family: "SpaceGrotesk";
            font-size: 28px;

            &:first-child {
                width: 42.5%;
            }
        }
        tbody {
            td {
                height: auto;
                padding: 24px 24px;
                text-align: center !important;
                width: 220px;
                font-size: 16px;
                color: rgba(43, 47, 56, 1);
            }
        }
        tr {
            th:nth-last-child(1) {
                background: rgb(255, 0, 162);
                color: white;
            }
            td:nth-last-child(1) {
                background: rgb(255, 0, 162);
                color: white;
            }
            th:nth-last-child(2) {
                background: rgba(249, 249, 249, 1);
            }
            td:nth-last-child(2) {
                background: rgba(249, 249, 249, 1);
            }
        }
        .secondLine {
            span {
                line-height: 26px;
            }
        }
        .title {
            text-align: left !important;
            padding-left: 40px;
            font-size: 16px;
            color: rgba(43, 47, 56, 1);

        }
        .lightTitle {
            // color: rgba(122, 132, 153, 1);
            color: rgba(43, 47, 56, 1);
            font-weight: 700;
            font-size: 15px !important;
            text-transform: uppercase;

        }
        .darkLine {
            td {
                box-shadow: inset 0px 0px 100px 1000px rgba(99, 99, 99, 0.1) !important;
            }
        }
        .displayNone {
            width: 0px !important;
            display: none;
            background: red !important;
        }
        thead tr {
            background: rgba(230, 231, 235, 1);
            width: 220px;
        }
        tbody tr {
            background: rgba(230, 231, 235, 1);
            &:last-child {
                border: 0;
            }
        }
        tfoot {
            tr {
                background: rgba(230, 231, 235, 1);
                height: 16px;
            }
        }
    }

    @media (max-width: 1150px) {
        .tariffsSwitch {
            display: flex;
        }
        table {
            width: 96%;
            border-radius: 32px;
            max-width: 960px;

            tbody {
                td {
                    height: auto;
                    padding: 24px 24px 24px 0px;
                    text-align: right !important;
                    width: 60%;
                }
                .tariffInfo {
                    width: 40%;
                }
                .lightTitle {
                    width: 100%;
                    padding: 24px 24px 0 32px;
                }
            }
            .title {
                padding-left: 32px;
            }
            tr {
                th:nth-last-child(1) {
                    background: rgba(230, 231, 235, 1);
                    color: rgba(43, 47, 56, 1);
                }
                td:nth-last-child(1) {
                    background: rgba(230, 231, 235, 1);
                    color: rgba(43, 47, 56, 1);
                }
                th:nth-last-child(2) {
                    background: rgba(230, 231, 235, 1);
                }
                td:nth-last-child(2) {
                    background: rgba(230, 231, 235, 1);
                }
            }
            tfoot {
                tr {
                    background: rgba(230, 231, 235, 1);
                    height: 16px;
                }
            }
        }
        thead {
            display: none;
        }
    }
    @media (max-width: 750px) {
        .tariffsSwitch {
            width: 100%;
            justify-content: center;
            label {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 31.8%;
                height: 45px;
                transition: 0.2s;
            }
        }
    }
}
