.news {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;
    background: #f2f3f5;
    padding: 62px 32px 150px 32px;
    min-height: 70vh;

    span {
        font-size: 14px;
        font-weight: 600;
        color: rgba(78, 85, 102, 1);
    }
    h1 {
        text-align: center;
        font-family: "SpaceGrotesk";
        color: rgb(43, 47, 56);
        font-size: 32px;
        max-width: 700px;
        font-weight: 400;
        // color: rgba(78, 85, 102, 1);
        padding-bottom: 30px;

        @media (max-width: 450px) {
            font-size: 26px !important;
        }
    }

    .newsContainer {
        margin-top: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 30px;
        flex-wrap: wrap;
        width: 80%;

        &:after {
            content: "";
            background-color: red;
            width: 23%;

            @media (max-width: 1650px) {
                width: 31%;
            }
            @media (max-width: 965px) {
                width: 48%;
            }
        }
        &::before {
            content: "";
            background-color: red;
            width: 23%;
            order: 2;

            @media (max-width: 1650px) {
                width: 31%;
            }
            @media (max-width: 965px) {
                width: 48%;
            }
        }

        @media (max-width: 1650px) {
            width: 90%;
        }
    }

    @media (max-width: 1150px) {
        .newsContainer {
            margin-top: 20px;
            width: 100%;
        }
    }
    @media (max-width: 965px) {
        padding: 62px 32px 50px 32px;

        .newsContainer {
            margin-top: 20px;
            width: 100%;
            justify-content: space-evenly;
        }
    }
    @media (max-width: 660px) {
        h1 {
            font-size: 28px;
        }
        padding: 62px 32px 0px 32px;
    }
}
