.blog {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;
    background: #f2f3f5;
    padding: 62px 32px 100px 32px;
    min-height: 70vh;

    span {
        font-size: 14px;
        font-weight: 600;
        color: rgba(78, 85, 102, 1);
    }
    h1 {
        text-align: center;
        font-family: "SpaceGrotesk";
        color: rgb(43, 47, 56);
        font-size: 32px;
        max-width: 700px;
        font-weight: 400;
        // color: rgba(78, 85, 102, 1);
        padding-bottom: 30px;

        @media (max-width: 450px) {
            font-size: 26px !important;
        }
    }

    .blogContainer {
        max-width: 1170px;
        display: flex;
        flex-direction: column;
        align-items: center;
        // flex-wrap: wrap;
        margin-top: 50px;
        gap: 65px;

        @media (max-width: 950px) {
            margin-top: 20px;
        }
        @media (max-width: 550px) {
            margin-top: 0px;
            gap: 45px;
        }

        .blogPagination {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    // @media (max-width: 1250px) {
    //     padding: 62px 32px 50px 32px;
    //     .researchContainer {
    //         margin-top: 20px;
    //         width: 100%;
    //         gap: 25px;
    //     }
    // }
}
